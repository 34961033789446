<template>
  <f7-page name="form">
  
      <f7-navbar>
  <f7-nav-left><f7-link href="#" @click="goBack()"><f7-icon icon="icon-back"></f7-icon> Settim.</f7-link></f7-nav-left>
  <f7-nav-title>{{pageTitle}}</f7-nav-title>
  <f7-nav-right>
    <f7-link v-if="showSaveBtn && isInSentWeek == 'false'" @click="save()">Salva</f7-link>
    <f7-link v-if="showDeleteBtn && isInSentWeek == 'false'" @click="deleteItem()"><f7-icon ios="f7:trash" aurora="f7:trash" md="material:trash" color-red></f7-icon></f7-link>
  </f7-nav-right>
</f7-navbar>
      
      
     <f7-toolbar tabbar position="bottom">
		<f7-link tab-link="#tab-add" @click="fillTimesheet()" :disabled="isInSentWeek == 'true'" :tab-link-active="isInSentWeek == 'false'">
			<i class="icon f7-icons if-not-md">plus</i>
			<span class="tabbar-label">Inserimento</span>
		</f7-link>
		
		<f7-link tab-link="#tab-edit" @click="getTimesheet()" :tab-link-active="isInSentWeek == 'true'">
			<i class="icon f7-icons if-not-md">today_fill<span v-if="!timesheetIsEmpty" class="badge badge-timesheet color-red">&nbsp;</span></i>
            <span class="tabbar-label">Gestione</span>
		</f7-link>
	</f7-toolbar>
	
	<f7-tabs>
		<f7-tab id="tab-add" :tab-active="isInSentWeek == 'false'">
		
			<f7-card v-if="isInSentWeek == 'true'" content="Questa data appartiene ad una settimana già inviata. Contatta il Personale per richiederne l'eventuale sblocco."></f7-card>
			
			<div v-if="isInSentWeek == 'false'">
		
				<f7-swiper ref="formSwiper" class="tabs-swipeable-wrap formSwiper" @slideChange="onSlideChange">
				
					<f7-swiper-slide class="swipeslide1">
					
					<p class="text-align-center"><f7-badge color="green">1</f7-badge> <f7-badge>2</f7-badge> <f7-badge>3</f7-badge></p>
					<h2 class="text-align-center">Attività</h2>
						<f7-block-header>Seleziona e scorri per procedere <f7-icon f7="arrow_right"></f7-icon></f7-block-header>
						<f7-list media-list>
							<f7-list-item :disabled="isInSentWeek == 'true'" @change="step1($event.target.value, item.activity_working)" :checked="radio_activity === item.activity_id" v-for="item in activities" radio :value="item.activity_id" radio-icon="end" :title="item.activity_name" :subtitle="item.company_name" name="radio_activity"></f7-list-item>
						</f7-list>
						
					</f7-swiper-slide>
					
					
					<f7-swiper-slide class="swipeslide2">
					<p class="text-align-center"><f7-badge color="green">1</f7-badge> <f7-badge color="green">2</f7-badge> <f7-badge>3</f7-badge></p>
						<h2 class="text-align-center">Tipologia</h2>
						
						<f7-block-header v-if="timeTypes.length > 0">Seleziona e scorri per procedere <f7-icon f7="arrow_right"></f7-icon></f7-block-header>
						<f7-block-header v-if="timeTypes.length == 0"><f7-icon f7="arrow_left"></f7-icon> Scorri per selezionare un'Attività</f7-block-header>
						
						<f7-list v-if="timeTypes.length > 0">
							<f7-list-item :disabled="isInSentWeek == 'true'" @change="step2($event.target.value)" v-for="item in timeTypes" :checked="radio_timetype === item.id" radio :value="item.id" radio-icon="end" :title="item.name" name="radio_timetype"></f7-list-item>
						</f7-list>
					</f7-swiper-slide>
					
					<f7-swiper-slide class="swipeslide3">
						<p class="text-align-center"><f7-badge color="green">1</f7-badge> <f7-badge color="green">2</f7-badge> <f7-badge color="green">3</f7-badge></p>
						<h2 class="text-align-center">Quantità di ore</h2>
	
						<f7-block-header v-if="!activityIsSelected"><f7-icon f7="arrow_left"></f7-icon> Scorri per selezionare un'Attività</f7-block-header>
						<f7-block-header v-if="!timeTypeIsSelected"><f7-icon f7="arrow_left"></f7-icon> Scorri per selezionare una Tipologia</f7-block-header>
						<f7-block-header v-if="activityIsSelected && timeTypeIsSelected">Seleziona una quantità poi Salva</f7-block-header>
						
						<f7-list v-if="timeTypeIsSelected && hours.length > 0">
							<f7-list-item :disabled="isInSentWeek == 'true'" @change="step3($event.target.value)" v-for="item in hours" :checked="radio_hour === item.value" radio :value="item.value" radio-icon="end" :title="item.label" name="radio_hour"></f7-list-item>
						</f7-list>
					</f7-swiper-slide>
				</f7-swiper>
			
			</div>
			
		</f7-tab>
		
		<f7-tab id="tab-edit" :tab-active="isInSentWeek == 'true'">
		
			<f7-card v-if="timesheetIsEmpty" content="Non sono disponibili informazioni salvate per questa data."></f7-card>
		
			<f7-list media-list v-if="savedTimesheet.length > 0">
				<f7-list-item :disabled="isInSentWeek == 'true'" v-for="item in savedTimesheet" 
				:checkbox="isInSentWeek == 'false'" 
				name="timesheet_checkbox[]"
				:title="item.activity_name"
				:subtitle="item.company_name"
				:text="item.type_name"
				:value="item.id"
				>
					<template #header v-if="item.schedulated == 1"><f7-icon ios="f7:clock" aurora="f7:clock" md="material:clock" style="color:#888;"></f7-icon> <span class="badge color-green">PIANIFICATA</span></template>
				
					<template #after><f7-badge style="color:#000; font-weight: bold;">{{item.time_hours.replace('.', ',')+' ore'}}</f7-badge></template>
					
					<template #footer>
					
						<f7-list-input
							class="timesheet_checkbox_hours"
							:input-id="'timesheet_checkbox_hour_'+item.id"
							label="Pianifica questo modello di Attività"
							type="select"
							placeholder=""
							:name="'timesheet_checkbox_hours['+item.id+']'"
						>
							<option value="">...</option>
							<option :value="c.id" v-for="c in calendarTypes">{{c.label}}</option>
						</f7-list-input>
					
					</template>
					
				</f7-list-item>
			</f7-list>
			
			
			
	
			<f7-fab v-if="!timesheetIsEmpty" position="center-bottom" @click="setCaledarItem()" style="position: fixed;">
				<f7-icon ios="f7:calendar_badge_plus" aurora="f7:calendar_badge_plus" md="material:calendar_badge_plus"></f7-icon>
			</f7-fab>
			
			
		</f7-tab>
		
	</f7-tabs>
	
	
	<f7-popup class="calendar-config-popup">
	<f7-page>
	
	<f7-navbar>
	<f7-nav-left><f7-link popup-close><f7-icon icon="icon-back"></f7-icon></f7-link></f7-nav-left>
	<f7-nav-title>Pianifica operazioni</f7-nav-title>
	<f7-nav-right>
	<f7-link>&nbsp;</f7-link>
	</f7-nav-right>
	</f7-navbar>

	</f7-page>
	</f7-popup>


  </f7-page>
</template>

<script>
import { f7, useStore, f7ready, f7router } from 'framework7-vue';
import dom7 from 'dom7';
import { onMounted } from 'vue';
import store from '../js/store';
import rightPanel from './right-panel.vue';
import config from '../js/config';
import ajax from '../js/ajax';

export default {
	props: {
	  timedate: String,
	  weekNumber: String,
      pageTitle: String,
      isEmpty: String,
      isInSentWeek: String,
    },
    computed: {
      swiper() {
        return this.$refs.formSwiper;
      }
    },
	data() {
		return {
			user: null,
			loginState: false,

			activities: [], //user obj
			timeTypes: [], //user obj
			hours: [], //user obj
			
			activityIsSelected: false,
			timeTypeIsSelected: false,
			hourIsSelected: false,
			
			activitySelectedValue: 0,
			typeSelectedValue: 0,
			hourSelectedValue: 0,
			
			savedTimesheet: [],
			showEmptyMessage: false,
			timesheetIsEmpty: true,
			
			showSaveBtn: true,
			showDeleteBtn: false,
			pageSwiper: null,
			
			calendarTypes:config.calendarTypes
		};
	},
	mounted() {
		self = this;
								
		f7ready((f7) => {
			
			console.log('$refs', self.$refs);
			
			self.timesheetIsEmpty = (self.isEmpty == 'true');
						
			store.dispatch('checkLoginState');
			self.loginState = useStore('getLoginState');
					
			self.user = useStore('getUserData');
						
			if (self.user.activities !== 'undefined' && self.user.activities.length > 0)
				self.activities = self.user.activities;
				
				if (self.isInSentWeek == 'true') {
					self.getTimesheet();
				}
				
			for (var i = 7.5; i > -1; i--) {
				var v = i+=0.5;
				if (i > 0) {
					self.hours.push({value:v, label:v.toString().replace('.', ',')});
				}
			}
			
			console.log('this is current swiper instance object', self.mySwiper)
			
			//self.pageSwiper = document.querySelector('.swiper-container').swiper;
			//self.pageSwiper = document.querySelector('.formSwiper').swiper;
			//console.log('self.$refs.formSwiper', self.$refs.formSwiper);
			//self.pageSwiper = self.$refs.formSwiper.$swiper;
			
			setTimeout(function() {
				if (dom7('.formSwiper').length > 0)
					self.pageSwiper = document.querySelector('.formSwiper').swiper;
			}, 500);
			
			console.log('weekNumber', self.weekNumber);
			
			//console.log('self.hours', self.hours);
		});
	},
	methods: {
		onSlideChange() {
			const self = this;
			dom7('.page-current .page-content').scrollTop(0, 300);
		},
		goBack() {
			const self = this;
			
			if (self.activityIsSelected || self.timeTypeIsSelected || self.hourIsSelected) {
				f7.dialog.confirm("Sono state eseguite delle selezioni, abbandonare ora la compilazione?", () => {
					f7.views.main.router.navigate('/'+self.timedate+'/');
				});
			} else {
				f7.views.main.router.navigate('/'+self.timedate+'/');
			}
		},
		
		/*editWeekDay(timedate) {
			const self = this;
						
			self.activities = self.user.activities;
		},*/
		
		fillTimesheet() {
			const self = this;
			self.showSaveBtn = true;
			self.showDeleteBtn = false;
		},
		
		getTimesheet() {
			const self = this;
			self.showSaveBtn = false;
			self.showDeleteBtn = true;
			
			/*if (self.isEmpty == 'true') {
				self.showEmptyMessage = true;
				self.timesheetIsEmpty = true;
				self.savedTimesheet = [];
				return;
			}*/
			
			//Prova
			if (self.isEmpty == 'true')
				return;
			
			ajax.getTimesheet({appToken:self.user.appToken, appVersion:config.appVersion, timeDate:self.timedate},
			
				function(data, status, xhr) {
					
					console.log('getTimesheet', data);
					
					if (data.status == 'ok') {
						
						self.savedTimesheet = data.timesheet;
						dom7('input[type="checkbox"]').prop('checked', false);
						
						if (data.timesheet.length == 0) {
							self.showEmptyMessage = true;
							self.timesheetIsEmpty = true;
						} else {
							self.showEmptyMessage = false;
							self.timesheetIsEmpty = false;
						}

	        		} else {
		        		
						f7.dialog.alert(data.msg, () => {
							if (data.forcelogout !== 'undefined' && parseInt(data.forcelogout) == 1) {
					        	store.dispatch('logout');
								f7.views.main.router.refreshPage();
				        	}							
						});
						self.showEmptyMessage = true;
		        		
	        		}
					
					f7.preloader.hide();
				},
				
				function(xhr, status, message) {
					self.showEmptyMessage = true;
					f7.preloader.hide();
					f7.dialog.alert(status, () => {
						store.dispatch('logout');
						f7.views.main.router.navigate('/');
	        		});
				}
			);
		},
		
		step1(checked_value, working) {
			const self = this;
			
			self.timeTypes = [];
			
			if (self.user.timeTypes.length > 0) {
				for (var i in self.user.timeTypes) {
					
					if (self.user.timeTypes[i].working == working) {
						self.timeTypes.push(self.user.timeTypes[i]);
					}
				}
				
				self.activityIsSelected = true;
				self.activitySelectedValue = checked_value;
				console.log('activity id', checked_value);
				
				//Nel passaggio dalla prima alla seconda slide le tipologie di ore possono cambiare (lavoro/non lavoro) quindi le disattivo se 
				//eventualmente è stata selezionata una diversa attività nella prima slide
				dom7('input[name="radio_timetype"]').prop('checked', false);
				self.timeTypeIsSelected = false;
				self.typeSelectedValue = 0;
				
				self.pageSwiper.slideNext();
			}
			//console.log('timeTypes', self.timeTypes);
		},
		
		step2(checked_value) {
			const self = this;
			self.timeTypeIsSelected = true;
			self.typeSelectedValue = checked_value;
			console.log('type id', checked_value);
			self.pageSwiper.slideNext();
		},
		
		step3(checked_value) {
			const self = this;
			
			self.hourIsSelected = true;
			self.hourSelectedValue = checked_value;
			console.log('hour', checked_value);
		},
		
		save() {
			const self = this;
			
			if (!self.activityIsSelected) {
				f7.dialog.alert("Seleziona un'Attività", () => {});
				self.pageSwiper.slideTo(0);
				return;
			}
			
			if (!self.timeTypeIsSelected) {
				f7.dialog.alert("Seleziona una Tipologia", () => {});
				self.pageSwiper.slideTo(1);
				return;
			}
			
			if (!self.hourSelectedValue) {
				f7.dialog.alert("Seleziona una quantità di ore", () => {});
				self.pageSwiper.slideTo(2);
				return;
			}
			
			ajax.saveTimesheet({appToken:self.user.appToken, appVersion:config.appVersion, activityId:self.activitySelectedValue, typeId:self.typeSelectedValue, timeHours:self.hourSelectedValue, weekNumber:self.weekNumber, timeDate:self.timedate}, 
			
				function(data, status, xhr) {
					
					if (data.status == 'err') {
			        	f7.dialog.alert(data.msg, () => {
							if (data.forcelogout !== 'undefined' && parseInt(data.forcelogout) == 1) {
					        	store.dispatch('logout');
								f7.views.main.router.refreshPage();
				        	}
	        			});
	        			
	        			console.log('retdata', data);
	        		} else {
		        		
		        		f7.dialog.alert('Le informazioni sono state inserite correttamente.', () => {
			        		//self.resetValues();
			        		f7.views.main.router.navigate(
			        			f7.views.main.router.currentRoute.url, 
				        		{
									reloadCurrent: true,
									ignoreCache: true,
									props: {
										isEmpty:'false'
									}
								}
							);
			        		//self.getTimesheet();
	        			});
		        		
	        		}
					
					f7.preloader.hide();
				}, 
				
				function(xhr, status, message) {
					f7.preloader.hide();
					f7.dialog.alert(status, () => {
						store.dispatch('logout');
						f7.views.main.router.navigate('/');
	        		});
				}
			);
		},
		
		resetValues() {
			const self = this;
			
			self.activityIsSelected = false;
			self.timeTypeIsSelected = false;
			self.hourIsSelected = false;
			
			self.activitySelectedValue = 0;
			self.typeSelectedValue = 0;
			self.hourSelectedValue = 0;
			
			self.timeTypes = [];
			
			dom7('input[type="radio"]').prop('checked', false);
			dom7('input[type="checkbox"]').prop('checked', false);
			
		},
		
		deleteItem() {
			const self = this;
			var checked = [];
						
			dom7('input[type="checkbox"]:checked').each(function() {
                checked.push(dom7(this).val());
            });
            
            if (checked.length < 1) {
	            f7.dialog.alert("Selezionare almeno un'Attività da eliminare.", () => {});
	            return;
            } else {
	            f7.dialog.confirm("Eliminare le Attività selezionate?<br><br>Nota: se una o più delle Attività selezionate sono state schedulate e desideri interromperne la compilazione automatica, andranno rimosse o sospese anche nell'area <b>Attività pianificate</b>.", () => {
					ajax.deleteTimesheet({appToken:self.user.appToken, appVersion:config.appVersion, tsList:checked},
						function(data, status, xhr) {
							
							if (data.status == 'ok') {
								self.getTimesheet();
							} else {
								f7.dialog.alert(data.msg, () => {
									if (data.forcelogout !== 'undefined' && parseInt(data.forcelogout) == 1) {
							        	store.dispatch('logout');
										f7.views.main.router.refreshPage();
						        	}
								});
								f7.preloader.hide();
							}
						},
						function(xhr, status, message) {
							f7.preloader.hide();
							f7.dialog.alert(status, () => {
								store.dispatch('logout');
								f7.views.main.router.navigate('/');
							});
						}
					);
				});
            }

		},
		
		setCaledarItem() {
			const self = this;
			var checked = [];
			var obj = [];
			var errors = 0;
			const conf_popup = f7.popup.get('.calendar-config-popup'); //Non utilizzata al momento
			
			if (self.isInSentWeek == 'true') {
				f7.dialog.alert("Non è possibile pianificare attività di una settimana già inviata. Per creare una nuova pianificazione, scegliere un'attività di una settimana non ancora inviata, o aggiungerne una nuova ad una data non compilata.", () => {});
				return;
			}
			
			//conf_popup.open();
			
			//Deseleziona tutte le checkbox della pagina 
									
			dom7('input[type="checkbox"]:checked').each(function() {
                checked.push(dom7(this).val());
            });
            
            if (checked.length < 1) {
	            f7.dialog.alert("Selezionare almeno un'Attività da pianificare.", () => {});
            } else {
	            
	            for (var i in checked) {
		            if (dom7('#timesheet_checkbox_hour_'+checked[i]).length) {
			            var did = dom7('#timesheet_checkbox_hour_'+checked[i]).val();
			            
			            if (did != '') {
		           			obj.push({id:checked[i], day_id:did});
				   		} else {
					   		errors++;
				   		}
		            } else {
			            errors++;
		            }
	            }
	            
	            if (errors > 0) {
		            f7.dialog.alert("Selezionare una modalità di pianificazione per ciascuna delle Attività selezionate.", () => {});
	            } else {
		            //console.log(data);
		            
			        ajax.setCalendarItem({appToken:self.user.appToken, appVersion:config.appVersion, obj:obj},
						function(ret, status, xhr) {
							f7.preloader.hide();
							
							console.log('ret', ret);
							
							if (ret.status == 'err') {
								f7.dialog.alert(ret.msg, () => {});
							} else {
								f7.views.main.router.navigate('/calendar/');
							}
						},
						function(xhr, status, message) {
							f7.preloader.hide();
							f7.dialog.alert(status, () => {
								store.dispatch('logout');
								f7.views.main.router.navigate('/');
							});
						}
					);
		            
		            
	            }
	            
	        }
		}
	}
}
</script>