<template>
    <f7-view>
      <f7-page>
        <f7-navbar :title="displayName"></f7-navbar>
          <f7-list simple-list>
		  	<f7-list-item title="Logout" @click="logout()"></f7-list-item>
		  </f7-list>
      </f7-page>
    </f7-view>
</template>

<script>
  import { f7 } from 'framework7-vue';
  import store from '../js/store';
  
  export default {
    props: {
      displayName: String,
    },
    methods: {
	    logout() {
		    const self = this;
		    const panelRight = f7.panel.get('.panel-right');
			
    		f7.dialog.confirm("Eseguire il logout?", () => {
				store.dispatch('logout');
				panelRight.close();
				f7.views.main.router.refreshPage();
			});
	    }
    }
  }
</script>