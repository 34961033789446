
import { createStore } from 'framework7/lite';
import { f7 } from 'framework7-vue';

const store = createStore({
  state: {
	logStatus: false,
	user:null,
	isDarkMode:false,
	elementsColor:'',
	bgIndex:-1
  },
  
  getters: {
    getLoginState({state}) {
	    return state.logStatus;
    },
    getUserData({state}) {
	    return state.user;
    },
    getIsDarkMode({state}) {		
		let checkDarkMode = localStorage.getItem('isDarkMode');
		
		if (checkDarkMode == null) {
			state.isDarkMode = false;
			checkDarkMode = false;
		} else {
			
			if (checkDarkMode == 'true') {
				checkDarkMode = true;
				state.isDarkMode = true;
			} else {
				checkDarkMode = false;
				state.isDarkMode = false;
			}
		}
		
		return checkDarkMode;
    },
    getElementsColor({state}) {
	    let elementsColor = localStorage.getItem('elementsColor');
	    state.elementsColor = elementsColor;
		
		return elementsColor;
    },
    getBgIndex({state}) {
	    var bgIndex = localStorage.getItem('bgIndex');
	    
	    if (bgIndex != null) {
		    state.bgIndex = bgIndex;
	    } else {
		    state.bgIndex = -1;
			bgIndex = -1;
	    }
	    
	    return bgIndex;

    }
  },
  
  actions: {
	checkLoginState({state}) {
		const user = localStorage.getItem('user'); //app.vue
		if (user != null) {
			state.logStatus = true; 
			state.user = JSON.parse(user);
		} else {
			state.logStatus = false;
			state.user = null;
		} 
	},
	setUserData({state}, userData) {
		localStorage.removeItem('user');
        localStorage.setItem('user', JSON.stringify(userData));
        state.user = JSON.parse(localStorage.getItem('user'));
	},
	replaceUserData({state}, obj) {
		if (state.user != null) {
			state.user[obj.key] = obj.value;
			localStorage.removeItem('user');
			localStorage.setItem('user', JSON.stringify(state.user));
		}
	},
	logout({state}) {
		localStorage.removeItem('user');
		state.logStatus = false;
		state.user = null;
	},
	setDarkMode({state}, isDarkMode) {
		localStorage.removeItem('isDarkMode');
		localStorage.setItem('isDarkMode', isDarkMode);
		state.isDarkMode = isDarkMode;
	},
	setElementsColor({state}, elementsColor) {
		localStorage.removeItem('elementsColor');
		localStorage.setItem('elementsColor', JSON.stringify(elementsColor));
		state.elementsColor = elementsColor;
	},
	setBgIndex({state}, index) {
		localStorage.removeItem('bgIndex');
		localStorage.setItem('bgIndex', index);
		state.bgIndex = index;
	},
	removeBgIndex({state}) {
		localStorage.removeItem('bgIndex');
		state.bgIndex = -1;
	}
  },
})
export default store;
