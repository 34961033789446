<template>
  <f7-app v-bind="f7params" >

  <!-- Left panel with cover effect-->
  <!--<f7-panel left cover theme-dark>
    <f7-view>
      <f7-page>
        <f7-navbar title="Left Panel"></f7-navbar>
        <f7-block>Left panel content goes here</f7-block>
      </f7-page>
    </f7-view>
  </f7-panel>-->


  <!-- Right panel with reveal effect-->
  <!--<f7-panel right reveal theme-dark>
    <f7-view>
      <f7-page>
        <f7-navbar :title="displayName"></f7-navbar>
          <f7-list simple-list>
		  	<f7-list-item title="Set. ..."></f7-list-item>
		  	<f7-list-item title="Logout"></f7-list-item>
		  </f7-list>
      </f7-page>
    </f7-view>
  </f7-panel>-->
  
  <f7-panel v-if="$root.panelComponent" right reveal theme-dark>
    <component :is="$root.panelComponent" v-bind="$root.panelProps"></component>
  </f7-panel>
  

  <!-- Your main view, should have "view-main" class -->
  <f7-view main class="safe-areas" url="/"></f7-view>


    <!-- Popup -->
    <f7-popup id="my-popup">
      <f7-view>
        <f7-page>
          <f7-navbar title="Popup">
            <f7-nav-right>
              <f7-link popup-close>Close</f7-link>
            </f7-nav-right>
          </f7-navbar>
          <f7-block>
            <p>Popup content goes here.</p>
          </f7-block>
        </f7-page>
      </f7-view>
    </f7-popup>

    <f7-login-screen id="my-login-screen">
      <f7-view>
        <f7-page login-screen>
          <f7-login-screen-title>Login</f7-login-screen-title>
          
          
          <f7-list form>
            <f7-list-input
              type="text"
              name="username"
              placeholder="Nome utente" 
              info="@ttreinformatica.it o @ttre.it facoltativi"
              v-model:value="username"
            ></f7-list-input>
            <f7-list-input
              type="password"
              name="password"
              placeholder="Password"
              v-model:value="password"
            ></f7-list-input>
          </f7-list>
          <f7-list>
            <f7-list-button title="Entra" @click="alertLoginData"></f7-list-button>
            <f7-list-button title="Annulla" @click="closeLoginScreen"></f7-list-button>
            <f7-block-footer>
              <!--Some text about login information.<br>Click "Sign In" to close Login Screen-->
            </f7-block-footer>
          </f7-list>
        </f7-page>
      </f7-view>
    </f7-login-screen>
  </f7-app>
</template>
<script>
  import { ref, onMounted } from 'vue';
  import { f7, useStore, f7ready } from 'framework7-vue';
import dom7 from 'dom7';

  import routes from '../js/routes';
  import store from '../js/store';
  import config from '../js/config';
  import ajax from '../js/ajax';
  
	dom7(document).on('visibilitychange', function(state) {
		if (document.visibilityState === 'visible') {
			console.log('state visible: '+f7.views.main.router.url);
			var routerUrl = f7.views.main.router.url;
			var refreshPage = false;
			
			if (routerUrl == '/') {
				refreshPage = true;
			} else {
				var tmp = routerUrl.replace('/', '').replace('/', '');
				var check = Date.parse(tmp);
				if (!isNaN(check)) {
					refreshPage = true;
				}
			}
			
			if (refreshPage) {
				//f7.views.main.router.refreshPage();				
			}
			//console.log(f7.views.main.router);
		} else {
			console.log('state hidden');
		}			
	});

  export default {
	data() {
		return {
			panelComponent: null,
			panelProps: null,
			elementColors: config.elementColors
		};
	},
	methods: {
		closeLoginScreen() {
			const self = this;
			f7.loginScreen.close();
		}
	},
    setup() {
	    
	  const self = this;
	  
      // Framework7 Parameters
      const f7params = {
	    id: 'it.ttre.rapportino',
        name: 'Rapportino', // App name
        theme: 'aurora', 
        // App store
        store: store,
        // App routes
        routes: routes,
        
        autoDarkTheme: false,
       
        dialog: {
	        buttonCancel:'Annulla'
        }
      };
      
      // Login screen data
      var username = ref('');
      var password = ref('');
      
      //localStorage.removeItem('user');

      const alertLoginData = () => {	 
	      
	    ajax.login({username:username.value, password:password.value},
	    
	    	function(data, status, xhr) {
		    	
		    	console.log('login data', data);
		        
		        if (data.status == 'err') {
		        	f7.dialog.alert(data.msg, () => {
			        	store.dispatch('logout');
						f7.views.main.router.refreshPage();
						//f7.loginScreen.close();
        			});
        		} else {	        		
	        		f7.loginScreen.close();
	        		/*localStorage.removeItem('user');
	        		localStorage.setItem('user', JSON.stringify({
		        		appToken:data.app_token,
		        		avatar:data.avatar,
		        		displayName:data.display_name,
		        		timeTypes:data.time_types,
		        		activities:data.activities
	        		}));*/
	        		
	        		store.dispatch('setUserData', {
		        		appToken:data.app_token,
		        		avatar:data.avatar,
		        		avatarIsDefault:data.avatarIsDefault,
		        		displayName:data.display_name,
		        		timeTypes:data.time_types,
		        		activities:data.activities,
		        		loginUniqid:data.loginUniqid
	        		});
	        			        		
	        		//f7.preloader.hide();
	        		f7.views.main.router.refreshPage();
        		}
        		
        		password.value = '';
        		f7.preloader.hide()
		    	
	    	},
	    	
	    	function(xhr, status, message) {
		    	password.value = '';
		    	f7.preloader.hide()
		        f7.dialog.alert(status, () => {
					f7.loginScreen.close();
					store.dispatch('logout');
					f7.views.main.router.refreshPage();
        		});
	    	}
	    	
	    );     
        
      }
      onMounted(() => {
	      
	    const self = this;
	      
        f7ready((self) => {
          
          dom7('body').removeClass('theme-dark');
          
			var isDarkMode = useStore('getIsDarkMode');
			var colorData = useStore('getElementsColor');
			var elementsColorObj = JSON.parse(colorData.value);
			
			for(var i in self.elementColors) {
				if (self.elementColors[i].cssClass != '')
					dom7('body').removeClass(self.elementColors[i].cssClass);
			}
			
			if (elementsColorObj != null) {
				if (elementsColorObj.cssClass != '') {
					dom7('body').addClass(elementsColorObj.cssClass);
				}
			}
			
			if (isDarkMode.value) {
				dom7('body').addClass('theme-dark');
			}
          
        });
      });

      return {
        f7params,
        username,
        password,
        alertLoginData,
      }
    }
  }
</script>