const config = {
	appVersion:'1.0.0',
	request: {
		url:'https://ttre.it',
		method:'POST',
		dataType:'json',
		preloaderColor:'',
		timeout:60000,
	},
	weather: {
		cityId:'3169071',
		cityName:'Roma'
	},
	elementColors: [
		{
			cssClass:'',
			colorName:'Colore predefinito (blu)',
			badgeColor:'color-blue'
		},
		{
			cssClass:'color-theme-orange',
			colorName:'Arancione',	
			badgeColor:'color-orange'
		},
		{
			cssClass:'color-theme-lightblue',
			colorName:'Blu chiaro',	
			badgeColor:'color-lightblue'
		},
		{
			cssClass:'color-theme-pink',
			colorName:'Fucsia',	
			badgeColor:'color-pink'
		},
		{
			cssClass:'color-theme-teal',
			colorName:'Verde acqua',
			badgeColor:'color-teal'	
		},
		{
			cssClass:'color-theme-purple',
			colorName:'Viola',	
			badgeColor:'color-purple'
		}
	],
	weatherIcons: [
		{type:'01d', cssClass:'wi-day-sunny'},
		{type:'01n', cssClass:'wi-day-sunny'},
		{type:'02d', cssClass:'wi-day-cloudy'},
		{type:'02n', cssClass:'wi-day-cloudy'},
		{type:'03d', cssClass:'wi-cloud'},
		{type:'03n', cssClass:'wi-cloud'},
		{type:'04d', cssClass:'wi-cloudy'},
		{type:'04n', cssClass:'wi-cloudy'},
		{type:'09d', cssClass:'wi-showers'},
		{type:'09n', cssClass:'wi-showers'},
		{type:'10d', cssClass:'wi-rain'},
		{type:'10n', cssClass:'wi-rain'},
		{type:'11d', cssClass:'wi-day-thunderstorm'},
		{type:'11n', cssClass:'wi-day-thunderstorm'},
		{type:'13d', cssClass:'wi-snow'},
		{type:'13n', cssClass:'wi-snow'},
		{type:'50d', cssClass:'wi-fog'},
		{type:'50n', cssClass:'wi-fog'}
	],
	weatherIconsAccurate: [
		{type:'01d', cssClass:'wi-day-sunny'},
		{type:'01n', cssClass:'wi-night-clear'},
		{type:'02d', cssClass:'wi-day-cloudy'},
		{type:'02n', cssClass:'wi-night-alt-cloudy'},
		{type:'03d', cssClass:'wi-cloud'},
		{type:'03n', cssClass:'wi-cloud'},
		{type:'04d', cssClass:'wi-cloudy'},
		{type:'04n', cssClass:'wi-cloudy'},
		{type:'09d', cssClass:'wi-day-showers'},
		{type:'09n', cssClass:'wi-night-alt-showers'},
		{type:'10d', cssClass:'wi-day-rain'},
		{type:'10n', cssClass:'wi-night-alt-rain'},
		{type:'11d', cssClass:'wi-day-thunderstorm'},
		{type:'11n', cssClass:'wi-night-alt-thunderstorm'},
		{type:'13d', cssClass:'wi-day-snow'},
		{type:'13n', cssClass:'wi-night-alt-snow'},
		{type:'50d', cssClass:'wi-day-fog'},
		{type:'50n', cssClass:'wi-night-fog'}
	],
	calendarTypes: [
		{id:'-1', label:'Predefinito (esegui tutti i giorni)'},
		{id:'1', label:'Esegui solo il lunedì'},
		{id:'2', label:'Esegui solo il martedì'},
		{id:'3', label:'Esegui solo il mercoledì'},
		{id:'4', label:'Esegui solo il giovedì'},
		{id:'5', label:'Esegui solo il venerdì'},
	]
}

export default config;