import {f7} from 'framework7-vue';
import config from './config';

const ajax = {
	
	offlineMessage: function() {
		f7.dialog.alert("La connessione non è attiva, riprovare tra qualche istante.", () => {
			//f7.views.main.router.navigate('/offline/');
		});
	},
	
	login: function(params, successFunc, errorFunc) {
		
		if (!navigator.onLine) {
			this.offlineMessage();
			return;
		}
				
		setTimeout(function() {
			f7.preloader.show(config.request.preloaderColor);
		}, 100);
		
		f7.request({
	        url:config.request.url+'/dashboard/?dashboard_page=app-login.json',
	        method:config.request.method,
	        dataType:config.request.dataType,
	        data:{app_access1:params.username, app_access2:params.password, ttre_app_token:0, app_version:config.appVersion},
	        
	        success:function(data, status, xhr) {
		        
		        if (successFunc !== 'undefined')
		        	successFunc(data, status, xhr); 
			        
	        },
	        
	        error:function(xhr, status, message) {
		        f7.preloader.hide();
		        
		        if (errorFunc !== 'undefined')
		        	errorFunc(xhr, status, message);
	        }
        });
	},
	
	createWeekList: function (params, successFunc, errorFunc) {
		
		if (!navigator.onLine) {
			this.offlineMessage();
			return;
		}
		
		//Nasconde il loader quando si avvia il pull-to-refresh
		if (!params.hideLoader) {	
			setTimeout(function() {
				f7.preloader.show(config.request.preloaderColor);
			}, 100);
		}
		
		var monthView = 0;
		if (params.isMonthView === 'undefined') {
			monthView = 0;
		} else {
			monthView = params.isMonthView;
		}
		
		f7.request({
	        url:config.request.url+'/dashboard/?dashboard_page=app-get-week.json',
	        method:config.request.method,
	        dataType:config.request.dataType,
	        data:{ttre_app_token:params.appToken, app_version:config.appVersion, ws:params.weekstart, month_view:monthView},
	        
	        success:function(data, status, xhr) {
		        
		        if (successFunc !== 'undefined')
		        	successFunc(data, status, xhr);     
	        },
	        
	        error:function(xhr, status, message) {
		        f7.preloader.hide();
		        
		        if (errorFunc !== 'undefined')
		        	errorFunc(xhr, status, message);
	        }
	        
        });
	},
	
	saveTimesheet(params, successFunc, errorFunc) {
		
		if (!navigator.onLine) {
			this.offlineMessage();
			return;
		}
		
		setTimeout(function() {
			f7.preloader.show(config.request.preloaderColor);
		}, 100);
		
		f7.request({
	        url:config.request.url+'/dashboard/?dashboard_page=app-add-ts.json',
	        method:config.request.method,
	        dataType:config.request.dataType,
	        data:{ttre_app_token:params.appToken, app_version:params.appVersion, 'op':'add-ts', activity_id:params.activityId, type_id:params.typeId, time_hours:params.timeHours, week_number:params.weekNumber, time_date:params.timeDate},
	        
	        success:function(data, status, xhr) {
		        
		        if (successFunc !== 'undefined')
		        	successFunc(data, status, xhr);     
	        },
	        
	        error:function(xhr, status, message) {
		        f7.preloader.hide();
		        
		        if (errorFunc !== 'undefined')
		        	errorFunc(xhr, status, message);
	        }
	        
        });
	},
	
	getTimesheet(params, successFunc, errorFunc) {
		
		if (!navigator.onLine) {
			this.offlineMessage();
			return;
		}
		
		setTimeout(function() {
			f7.preloader.show(config.request.preloaderColor);
		}, 100);
		
		f7.request({
	        url:config.request.url+'/dashboard/?dashboard_page=app-get-day.json',
	        method:config.request.method,
	        dataType:config.request.dataType,
	        data:{ttre_app_token:params.appToken, app_version:params.appVersion, 'op':'get-added-ts', time_date:params.timeDate},
	        
	        success:function(data, status, xhr) {
		        
		        if (successFunc !== 'undefined')
		        	successFunc(data, status, xhr);     
	        },
	        
	        error:function(xhr, status, message) {
		        f7.preloader.hide();
		        
		        if (errorFunc !== 'undefined')
		        	errorFunc(xhr, status, message);
	        }
	        
        });
	},
	
	deleteTimesheet(params, successFunc, errorFunc) {
		
		if (!navigator.onLine) {
			this.offlineMessage();
			return;
		}
		
		setTimeout(function() {
			f7.preloader.show(config.request.preloaderColor);
		}, 100);
		
		f7.request({
	        url:config.request.url+'/dashboard/?dashboard_page=app-del-ts.json',
	        method:config.request.method,
	        dataType:config.request.dataType,
	        data:{ttre_app_token:params.appToken, app_version:params.appVersion, 'op':'del-ts', ts_list:params.tsList},
	        
	        success:function(data, status, xhr) {
		        
		        if (successFunc !== 'undefined')
		        	successFunc(data, status, xhr);     
	        },
	        
	        error:function(xhr, status, message) {
		        f7.preloader.hide();
		        
		        if (errorFunc !== 'undefined')
		        	errorFunc(xhr, status, message);
	        }
	        
        });
	},
	
	setCalendarItem(params, successFunc, errorFunc) {
		
		if (!navigator.onLine) {
			this.offlineMessage();
			return;
		}
		
		setTimeout(function() {
			f7.preloader.show(config.request.preloaderColor);
		}, 100);
		
		f7.request({
	        url:config.request.url+'/dashboard/?dashboard_page=app-add-calendar-item.json',
	        method:config.request.method,
	        dataType:config.request.dataType,
	        data:{ttre_app_token:params.appToken, app_version:params.appVersion, 'op':'add-ts', obj:params.obj},
	        
	        success:function(data, status, xhr) {
		        
		        if (successFunc !== 'undefined')
		        	successFunc(data, status, xhr);     
	        },
	        
	        error:function(xhr, status, message) {
		        f7.preloader.hide();
		        
		        if (errorFunc !== 'undefined')
		        	errorFunc(xhr, status, message);
	        }
	        
        });
	},
	
	getCalendarItems(params, successFunc, errorFunc) {
		if (!navigator.onLine) {
			this.offlineMessage();
			return;
		}
		
		setTimeout(function() {
			f7.preloader.show(config.request.preloaderColor);
		}, 100);
		
		f7.request({
	        url:config.request.url+'/dashboard/?dashboard_page=app-get-calendar-items.json',
	        method:config.request.method,
	        dataType:config.request.dataType,
	        data:{ttre_app_token:params.appToken, app_version:params.appVersion, 'op':'get-ts', obj:params.obj},
	        
	        success:function(data, status, xhr) {
		        
		        if (successFunc !== 'undefined')
		        	successFunc(data, status, xhr);     
	        },
	        
	        error:function(xhr, status, message) {
		        f7.preloader.hide();
		        
		        if (errorFunc !== 'undefined')
		        	errorFunc(xhr, status, message);
	        }
	        
        });
		
	},
	
	setCalendarItemStatus(params, successFunc, errorFunc) {
		if (!navigator.onLine) {
			this.offlineMessage();
			return;
		}
		
		setTimeout(function() {
			f7.preloader.show(config.request.preloaderColor);
		}, 100);
		
		f7.request({
	        url:config.request.url+'/dashboard/?dashboard_page=app-edit-calendar-item.json',
	        method:config.request.method,
	        dataType:config.request.dataType,
	        data:{ttre_app_token:params.appToken, app_version:params.appVersion, 'op':'set-status', item_id:params.itemId, status:params.status},
	        
	        success:function(data, status, xhr) {
		        
		        if (successFunc !== 'undefined')
		        	successFunc(data, status, xhr);     
	        },
	        
	        error:function(xhr, status, message) {
		        f7.preloader.hide();
		        
		        if (errorFunc !== 'undefined')
		        	errorFunc(xhr, status, message);
	        }
	        
        });
		
	},
	
	removeCalendarItem(params, successFunc, errorFunc) {
		if (!navigator.onLine) {
			this.offlineMessage();
			return;
		}
		
		setTimeout(function() {
			f7.preloader.show(config.request.preloaderColor);
		}, 100);
		
		f7.request({
	        url:config.request.url+'/dashboard/?dashboard_page=app-edit-calendar-item.json',
	        method:config.request.method,
	        dataType:config.request.dataType,
	        data:{ttre_app_token:params.appToken, app_version:params.appVersion, 'op':'remove-item', item_id:params.itemId},
	        
	        success:function(data, status, xhr) {
		        
		        if (successFunc !== 'undefined')
		        	successFunc(data, status, xhr);     
	        },
	        
	        error:function(xhr, status, message) {
		        f7.preloader.hide();
		        
		        if (errorFunc !== 'undefined')
		        	errorFunc(xhr, status, message);
	        }
	        
        });
		
	},
	
	setCalendarPeriod(params, successFunc, errorFunc) {
		if (!navigator.onLine) {
			this.offlineMessage();
			return;
		}
		
		setTimeout(function() {
			f7.preloader.show(config.request.preloaderColor);
		}, 100);
		
		f7.request({
	        url:config.request.url+'/dashboard/?dashboard_page=app-edit-calendar-item.json',
	        method:config.request.method,
	        dataType:config.request.dataType,
	        data:{ttre_app_token:params.appToken, app_version:params.appVersion, 'op':'set-period', period:params.period, type:params.type},
	        
	        success:function(data, status, xhr) {
		        
		        if (successFunc !== 'undefined')
		        	successFunc(data, status, xhr);     
	        },
	        
	        error:function(xhr, status, message) {
		        f7.preloader.hide();
		        
		        if (errorFunc !== 'undefined')
		        	errorFunc(xhr, status, message);
	        }
	        
        });
		
	},
	
	getCalendarPeriod(params, successFunc, errorFunc) {
		if (!navigator.onLine) {
			this.offlineMessage();
			return;
		}
		
		setTimeout(function() {
			f7.preloader.show(config.request.preloaderColor);
		}, 100);
		
		f7.request({
	        url:config.request.url+'/dashboard/?dashboard_page=app-edit-calendar-item.json',
	        method:config.request.method,
	        dataType:config.request.dataType,
	        data:{ttre_app_token:params.appToken, app_version:params.appVersion, 'op':'get-period'},
	        
	        success:function(data, status, xhr) {
		        
		        if (successFunc !== 'undefined')
		        	successFunc(data, status, xhr);     
	        },
	        
	        error:function(xhr, status, message) {
		        f7.preloader.hide();
		        
		        if (errorFunc !== 'undefined')
		        	errorFunc(xhr, status, message);
	        }
	        
        });
		
	},
	
	removeCalendarPeriod(params, successFunc, errorFunc) {
		if (!navigator.onLine) {
			this.offlineMessage();
			return;
		}
		
		var period_id = 0;
		
		setTimeout(function() {
			f7.preloader.show(config.request.preloaderColor);
		}, 100);
		
		if (typeof params.periodId === 'undefined') {
			period_id = 0;
		} else {
			period_id = params.periodId;
		}
		
		f7.request({
	        url:config.request.url+'/dashboard/?dashboard_page=app-edit-calendar-item.json',
	        method:config.request.method,
	        dataType:config.request.dataType,
	        data:{ttre_app_token:params.appToken, app_version:params.appVersion, 'op':'remove-period', 'period_id':period_id},
	        
	        success:function(data, status, xhr) {
		        
		        if (successFunc !== 'undefined')
		        	successFunc(data, status, xhr);     
	        },
	        
	        error:function(xhr, status, message) {
		        f7.preloader.hide();
		        
		        if (errorFunc !== 'undefined')
		        	errorFunc(xhr, status, message);
	        }
	        
        });
		
	},
	
	sendTimesheet(params, successFunc, errorFunc) {
		if (!navigator.onLine) {
			this.offlineMessage();
			return;
		}
		
		setTimeout(function() {
			f7.preloader.show(config.request.preloaderColor);
		}, 100);
		
		f7.request({
	        url:config.request.url+'/dashboard/?dashboard_page=app-send-ts.json',
	        method:config.request.method,
	        dataType:config.request.dataType,
	        data:{ttre_app_token:params.appToken, app_version:params.appVersion, 'op':'send-ts', ws:params.ws},
	        
	        success:function(data, status, xhr) {
		        
		        if (successFunc !== 'undefined')
		        	successFunc(data, status, xhr);     
	        },
	        
	        error:function(xhr, status, message) {
		        f7.preloader.hide();
		        
		        if (errorFunc !== 'undefined')
		        	errorFunc(xhr, status, message);
	        }
	        
        });
	},
	
	getMonthlyExport(params, successFunc, errorFunc) {
		if (!navigator.onLine) {
			this.offlineMessage();
			return;
		}
		
		setTimeout(function() {
			f7.preloader.show(config.request.preloaderColor);
		}, 100);
		
		
		/*var url = config.request.url+'/dashboard/?dashboard_page=timing-home';
		
		console.log('params', params);
		
		var xhr = new XMLHttpRequest();
		xhr.responseType = 'blob';
		
		xhr.onreadystatechange = function () {
			if (xhr.readyState === 4 && xhr.status === 200) {
				var url = (window.URL || window.webkitURL).createObjectURL(xhr.response);
				successFunc(url);
			}
		};
		
		xhr.onerror = function(xhr) {
			if (errorFunc !== 'undefined')
		        errorFunc(xhr);
		}
		
		console.log('send', {
			ttre_app_token: params.appToken,
			app_version: params.appVersion,
			op: 'monthly-export',
			ts_date: params.ws
		});
		

		xhr.open('POST', url);
		xhr.send(JSON.stringify({
			ttre_app_token: params.appToken,
			app_version: params.appVersion,
			op: 'monthly-export',
			ts_date: params.ws
		}));*/
		
		
		
		f7.request({
	        url:config.request.url+'/dashboard/?dashboard_page=timing-home',
	        method:config.request.method,
	        dataType:config.request.dataType,
	        data:{ttre_app_token:params.appToken, app_version:params.appVersion, 'op':'monthly-export', ts_date:params.ws},
	        
	        success:function(data, status, xhr) {
		        
		        if (successFunc !== 'undefined')
		        	successFunc(data, status, xhr);     
	        },
	        
	        error:function(xhr, status, message) {
		        f7.preloader.hide();
		        
		        if (errorFunc !== 'undefined')
		        	errorFunc(xhr, status, message);
	        }
	        
        });
	}
}

export default ajax;