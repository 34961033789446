<template>
  <f7-page name="home" ptr @ptr:refresh="pulltorefreshWeekList" :style="setBackgroundStyle('page')">
    <!-- Top Navbar -->


	<f7-navbar v-if="!deviceInfo.desktop">
	  <f7-nav-left>
	  	<f7-link v-if="loginState" icon-ios="f7:bars" icon-aurora="f7:bars" icon-md="material:bars" popover-open=".popover-menu"></f7-link>
	  </f7-nav-left>
	  <f7-nav-title>
	  	<div v-if="user!=null" class="text-align-center rounded-avatar-wrapper">
	  		<div class="rounded-avatar"><img :src="userAvatar"></div>
	  		<span>{{displayName}}</span>
	  	</div>
	  	<div v-if="user==null">Rapportino</div></f7-nav-title>
	  <f7-nav-right>
	    <f7-link v-if="loginState && !isSent" @click="sendTimesheet()" icon-ios="f7:arrow_up_circle_fill" icon-aurora="f7:arrow_up_circle_fill" icon-md="material:arrow_up_circle_fill"></f7-link>
	    <f7-icon v-if="loginState && isSent" f7="arrow_up_circle_fill" color="gray"></f7-icon>
	  </f7-nav-right>
	</f7-navbar>
	

	<div v-if="deviceInfo.desktop">
	    <f7-block>
		    <p class="text-align-center"><img src="static/icons/logo.png" style="width: 150px; height: 72px;"></p>
		    <p class="text-align-center">Apri l'app Rapportino sul tuo telefono</p>
		    <p class="text-align-center"><img src="static/qrcode.png" style="width: 300px; height: 300px;"></p>  
		    <p class="text-align-center">Se non riesci a fotografare il codice in alto, raggiungi <b>https://rapportino.ttre.it/</b> con <b>Chrome</b> o <b>Safari</b> del tuo telefono.</p>
			<p class="text-align-center">Scarica il <a href="#" @click="gotoManual()">Manuale Utente</a> (PDF).</p>
	    </f7-block>
	</div>

    <div v-if="!deviceInfo.desktop">
	    
	    
		<f7-block strong v-if="!loginState" :class="setBackgroundStyle('block')" style="padding:40px 20px 40px 20px;">
	    	
		    	<p class="text-align-center"><img src="static/icons/logo.png" style="width: 150px; height: 72px;"></p>	    
		    	<p>Effettua l'accesso per compilare il Rapportino settimanale.</p>
		    
		    	<f7-button fill raised login-screen-open="#my-login-screen">Login</f7-button>
	      
	    </f7-block>
		    
	    
	    
	  <f7-block v-if="loginState" :class="setBackgroundStyle('block')">
	  	<p class="text-align-center"><f7-icon f7="placemark_fill" size="15px"></f7-icon> Roma</p>
	  	<h3 align="center"><!--<i class="f7-icons" v-if="weekTitle != '...' && isCurrentWeek">house</i> -->{{weekTitle}} <f7-badge color="green" v-if="weekTitle != '...' && isSent">Inviata</f7-badge><f7-badge color="yellow" v-if="weekTitle != '...' && !isSent">Non inviata</f7-badge></h3>
	  	<p><f7-progressbar :progress="weekProgess"></f7-progressbar></p>
	  </f7-block>
	    
	    
	<f7-list v-if="loginState">	
		<!-- :disabled="isSent"-->		
		<f7-list-item :link="'/form/'+item.label+'/'+item.date+'/'+item.week_number+'/'+item.is_empty+'/'+isSent+'/'" v-for="item in pageData.labels" >
		
		<template #header>
			<div :class="item.is_today && !item.in_sent_week ? 'week-day-today-border' : ''">{{item.label_parts.month}}, {{item.label_parts.year}}</div>
		</template>
		
		<template #title>
			<div :class="item.is_today && !item.in_sent_week ? 'week-day-today-border' : ''">{{item.label_parts.day}}</div>
		</template>
		
			<template #media v-if="item.in_sent_week || item.weather.length > 0">
				<!--<f7-icon v-if="item.is_today && !item.in_sent_week" ios="f7:checkmark" aurora="f7:checkmark" md="material:checkmark"></f7-icon>-->
				
				<f7-button large small fill @click="getWeatherDay(item.label, item.weather)" v-if="item.weather.length > 0" style="width:40px; height: 26px;"><i :class="setWeatherIcon(item.weather[0].weather.icon, false)"></i></f7-button>
				<f7-icon v-if="item.in_sent_week" ios="f7:lock" aurora="f7:lock" md="material:lock"></f7-icon>
			</template>
			
			<template #after>
			<!--<span class="wather-deg-wrap">09-15&deg;</span>
            <span class="wather-wrap"><i class="wi wi-day-snow-wind"></i></span>-->
            <f7-badge :color="parseFloat(item.total_hours.replace(',', '.')) > 0 ? badgeColor : ''">{{item.total_hours}}</f7-badge>
          </template>
		</f7-list-item>
	</f7-list>
	      
	      
	      <f7-popover class="popover-menu">
	    <f7-list>
	      <f7-list-item link="#" popup-open=".install-popup" popover-close title="Installazione"></f7-list-item>
	      <f7-list-item link="/profile/" popover-close title="Profilo"></f7-list-item>
	      <f7-list-item link="#" v-if="!isSent" @click="sendTimesheet()" popover-close title="Invia settimana"></f7-list-item>
	      <f7-list-item link="#" @click="createWeekList(writableWs, 1, null, false)" popover-close title="Sintesi mensile"></f7-list-item>
	      <!--<f7-list-item link="/paypockets/" popover-close title="Buste paga"></f7-list-item>-->
	      <f7-list-item link="/calendar/" popover-close title="Attività pianificate"></f7-list-item>
	      <f7-list-item link="#" @click="refreshAll" popover-close title="Aggiorna"></f7-list-item>
	      <f7-list-item link="#" @click="logout" popover-close title="Logout"></f7-list-item>
	    </f7-list>
	  </f7-popover>
	  
	        <f7-popover class="popover-send">
	    <f7-list>
	      <f7-list-item link="#" @click="" popover-close title="Invia settimana"></f7-list-item>
	    </f7-list>
	  </f7-popover>
	  
	 <f7-popup class="weather-popup">
	    <f7-page>
	    
	    <f7-navbar>
	  <f7-nav-left><f7-link popup-close><f7-icon icon="icon-back"></f7-icon></f7-link></f7-nav-left>
	  <f7-nav-title>{{weatherTitle}}</f7-nav-title>
	  <f7-nav-right>
	    <f7-link>&nbsp;</f7-link>
	  </f7-nav-right>
	</f7-navbar>
	
<div class="timeline timeline-sides" v-if="weatherData.length > 0">
	
  <div class="timeline-item" v-for="item in weatherData">
    <div class="timeline-item-date">{{item.time}}</div>
    <div class="timeline-item-divider"></div>
    <div class="timeline-item-content">
      <div class="timeline-item-inner">
	      <div class="text-align-center">
		      <div><i :class="setWeatherIcon(item.weather.icon, true)"></i></div>
		      <div><small>{{item.weather.description}}</small></div>
		      <div>{{(Math.ceil(item.main.temp_min+item.main.temp_max)/2)|0}}&deg;</div>
		      <div><i class="wi wi-strong-wind"></i> {{item.wind.speed+' Km/h'}}</div>
		  </div>
      </div>
    </div>
  </div>
</div>
     
	    </f7-page>
	  </f7-popup>
	  
	  <f7-popup class="month-popup">
	    <f7-page>
	    
	    <f7-navbar>
	  <f7-nav-left><f7-link popup-close><f7-icon icon="icon-back"></f7-icon></f7-link></f7-nav-left>
	  <f7-nav-title>{{monthTitle}}</f7-nav-title>
	  <f7-nav-right>
	    <f7-link href="#" @click="exportMonth()">Esporta</f7-link>
	  </f7-nav-right>
	</f7-navbar>
	
	<f7-list v-if="loginState">	
		<!-- :disabled="item.in_sent_week" -->		
		<f7-list-item :link="'/form/'+item.label+'/'+item.date+'/'+item.week_number+'/'+item.is_empty+'/'+item.in_sent_week+'/'" @click="closeMonthPopup()" v-for="item in monthData.labels" :header="item.label_parts.month+', '+item.label_parts.year" :title="item.label_parts.day" :badge="item.total_hours" :badge-color="parseFloat(item.total_hours.replace(',', '.')) > 0 ? badgeColor : ''">
			<template #media>
				<f7-icon v-if="item.is_today && !item.in_sent_week" ios="f7:checkmark" aurora="f7:checkmark" md="material:checkmark"></f7-icon>
				<f7-icon v-if="item.in_sent_week" ios="f7:lock" aurora="f7:lock" md="material:lock"></f7-icon>
			</template>
		</f7-list-item>
	</f7-list>
	        
	    </f7-page>
	  </f7-popup>
	  
	    <f7-popup class="install-popup">
	    <f7-page>
	    
	    <f7-navbar>
	  <f7-nav-left><f7-link popup-close><f7-icon icon="icon-back"></f7-icon></f7-link></f7-nav-left>
	  <f7-nav-title>Installazione</f7-nav-title>
	  <f7-nav-right>
	    <f7-link>&nbsp;</f7-link>
	  </f7-nav-right>
	</f7-navbar>
	      
	      <f7-block v-if="deviceInfo.android">
	      	<h1 class="text-align-center"><i class="f7-icons">logo_android</i> Android</h1>
	      	<hr>
	      	<f7-row>
	      		<f7-col><h3>Installazione</h3></f7-col>
	      		<f7-col><h3 class="text-align-right"><f7-badge color="green" v-if="standalone">Installata</f7-badge><f7-badge color="red" v-if="!standalone">Non installata</f7-badge></h3></f7-col>
	      	</f7-row>
	      	<hr>
	      	<p>Al momento, per i sistemi Android, è possibile creare un collegamento all'app sulla schermata Home.</p>
	      	
	      	<ol>
		      	<li>Raggiungi <b>https://rapportino.ttre.it</b> con Chrome</li>
		      	<li>Tocca l'icona <b>menu Altro</b> <img src="static/icons/menu_more.png" width="15"></li>
		      	<li>Seleziona la voce di menu <b>Aggiungi alla schermata Home</b></li>
	      	</ol>
	      	
	      	<hr>
	      	
	      	<h3>Disinstallazione</h3>
	      	<hr>
	      	<p>Per disinstallare la WebApp <b>Rapportino</b> dal proprio dispositivo, eliminare il collegamento dalla schermata Home.</p>
	      	
	      </f7-block>
	      
	      <f7-block v-if="!deviceInfo.android">
	      	<h1 class="text-align-center"><i class="f7-icons">logo_apple</i> iOS</h1>
	      	<hr>
		  	<f7-row>
	      		<f7-col><h3>Installazione</h3></f7-col>
	      		<f7-col><h3 class="text-align-right"><f7-badge color="green" v-if="standalone">Installata</f7-badge><f7-badge color="red" v-if="!standalone">Non installata</f7-badge></h3></f7-col>
	      	</f7-row>
	      	<hr>
	      	<p>Al momento, per i sistemi Apple iOS, è possibile creare un collegamento all'app sulla schermata Home.</p>
	      	
	      	<ol>
		      	<li>Raggiungi <b>https://rapportino.ttre.it</b> con Chrome o Safari</li>
		      	<li>Tocca l'icona <b>Condividi</b> <img src="static/icons/share_apple.png" width="18"></li>
		      	<li>Seleziona la voce di menu <b>Aggiungi a schermata Home</b></li>
	      	</ol>
	      	
	      	<hr>
	      	
	      	<h3>Disinstallazione</h3>
	      	<hr>
	      	<p>Per disinstallare la WebApp <b>Rapportino</b> dal proprio dispositivo, eliminare il segnalibro dalla schermata Home.</p>
	      	
	      </f7-block>
	        
	    </f7-page>
	  </f7-popup>
	  
	  
  </div>
  

	<f7-toolbar v-if="loginState && !deviceInfo.desktop" position="bottom">
	<f7-link><f7-icon f7="arrow_turn_up_left" @click="createWeekList(backDate, 0, null, false)"></f7-icon></f7-link>
	  <f7-link @click="createWeekList('', 0)"><i class="f7-icons" v-if="!isCurrentWeek">house</i><i class="f7-icons" v-if="isCurrentWeek">house_fill</i></f7-link>
	  <f7-link><f7-icon f7="arrow_turn_up_right" @click="createWeekList(nextDate, 0, null, false)"></f7-icon></f7-link>
	</f7-toolbar>


  </f7-page>
</template>

<script>
import { f7, useStore, f7ready, f7router } from 'framework7-vue';
import dom7 from 'dom7';
import { getDevice } from 'framework7';
import { onMounted } from 'vue';
import store from '../js/store';
import rightPanel from './right-panel.vue';
import config from '../js/config';
import ajax from '../js/ajax';

export default {
	props: {
      f7router: Object,
      ws: String,
    },
	data() {
		return {
			showQRCode: false,
			deviceInfo: {},
			standalone: false,
			writableWs:'',
			user: null,
			userAvatar:null,
			displayName:null,
			loginState: false,
			pageData: {},
			monthData: {},
			monthTitle: '',
			badgeColor:'blue',
			
			weatherTitle:'',
			weatherData:[],
			
			weekProgess:0,
			weekTitle:'...',
			isCurrentWeek:false,
			isSent:true,
			backDate:'',
			nextDate:'',
		};
	},
	mounted() {
		const self = this;
				
		f7ready((f7) => {
			
			//self.setBackgroundStyle('page');
			
			self.deviceInfo = getDevice();
			//console.log('device', self.deviceInfo);
			
			self.standalone = self.deviceInfo.standalone;
			
			var ws = ''; //week start date
						
	        self.$root.panelComponent = rightPanel;
			self.$root.panelProps = {
				displayName: '...'
			}
			
			store.dispatch('checkLoginState');
			self.loginState = useStore('getLoginState');
					
			self.user = useStore('getUserData');
			
			var colorData = useStore('getElementsColor');
			var elementsColorObj = JSON.parse(colorData.value);
			
			//console.log('elementsColorObj', elementsColorObj);
			
			if (elementsColorObj != null) {
				self.badgeColor = elementsColorObj.badgeColor;
			}
					
			if (self.user != null) {
				console.log('self.user', self.user);
				self.$root.panelProps.displayName = self.user.displayName;
				
				self.userAvatar = self.user.avatar+'?t='+self.user.loginUniqid;
				self.displayName = self.user.displayName;
					
					
				if (self.loginState) {
					
					if (self.ws !== 'undefined')
						self.writableWs = self.ws;
						
					/*dom7(document).on('visibilitychange', function(state) {
						if (document.visibilityState === 'visible') {
							console.log('state visible');
							self.createWeekList(self.writableWs, 0, null, true);
						} else {
							console.log('state hidden');
						}			
					});*/
					
					self.createWeekList(self.writableWs, 0, null, false);
					
				}
			
			}
			
      	});
	},
	
	methods: {
		logout() {
		    const self = this;
		    const panelRight = f7.panel.get('.panel-right');
			
    		f7.dialog.confirm("Eseguire il logout?", () => {
				store.dispatch('logout');
				f7.views.main.router.refreshPage();
			});
	    },
		
		gotoManual() {
			window.open('https://rapportino.ttre.it/Rapportino-TTRE.pdf');
		},
	    
	    setBackgroundStyle(type) {
		    var bgIndex = useStore('getBgIndex');
		    var bgIndexFormatted = '';
		    var style = '';
		    
		    if (bgIndex.value > -1) {
			    
			    if (type == 'page') {
		    		bgIndexFormatted = bgIndex.value.toString().padStart(3, '0');
					style = 'background-image: url(static/bg'+bgIndexFormatted+'.jpeg); background-repeat: no-repeat; background-size: cover;';
		    	}
		    	
		    	if (type == 'block') {
			    	var isDarkMode = useStore('getIsDarkMode');
			    	
			    	if (isDarkMode.value) {
			    		style = 'page-header-block page-header-block-dark';
			    	} else {
				    	style = 'page-header-block page-header-block-light';
			    	}
			    }
		    }
		    
		    //console.log('bgIndex', bgIndex);
		    console.log('style', style);
		    		    
		    return style;
	    },
	    
	    closeMonthPopup() {
		    const self = this;
		    const monthPopup = f7.popup.get('.month-popup');
		    monthPopup.close();
	    },
	    
	    pulltorefreshWeekList(done) {
		    const self = this;
		    
		    if (self.user == null) {
		    	done();
		    	return;
		    }
		    
		    self.createWeekList(self.writableWs, 0, done, true);
	    },
	    
		createWeekList(weekstart, isMonthView, done, hideLoader) {
			const self = this;
			
			if (self.deviceInfo.desktop) {
				return;
			}
			
			const monthPopup = f7.popup.get('.month-popup');
			
			self.writableWs = weekstart;
			
			/*if (hideLoader !== 'undefined') {
				hideLoader = true;
			} else {
				hideLoader = false;
			}*/
						
			ajax.createWeekList({appToken:self.user.appToken, weekstart:self.writableWs, isMonthView:isMonthView, hideLoader:hideLoader},
			
				function(data, status, xhr) {
					console.log('week data', data);
			        
			        if (data.status == 'err') {
			        	f7.dialog.alert(data.msg, () => {
							if (data.forcelogout !== 'undefined' && parseInt(data.forcelogout) == 1) {
					        	store.dispatch('logout');
								f7.views.main.router.refreshPage();
				        	}
	        			});
	        		} else {
		        		
		        		if (isMonthView == 0) {
			        		self.pageData = data;
			        		self.weekProgess = data.totals.week_percentage;
			        		self.weekTitle = data.week_start_month_name+' '+data.week_start_year+', '+data.week_start_day+'-'+data.week_end_day
			        		self.isCurrentWeek = data.is_current_week;
			        		self.backDate = data.arrow_left_date;
			        		self.nextDate = data.arrow_right_date;
			        		self.isSent = data.already_sent;
		        		} else {
			        		self.monthData = data;
			        		self.monthTitle = data.week_start_month_name+' '+data.week_start_year;
			        		monthPopup.open();
		        		}
		        		
	        		}
	        		
	        		f7.preloader.hide();
	        		
	        		if (done !== 'undefined' && window.isFunction(done)) {
	        			done();
	        		}
				},
				
				function(xhr, status, message) {
					f7.preloader.hide();
					if (done !== 'undefined') {
						done();
					}
						
			        f7.dialog.alert(status, () => {
						store.dispatch('logout');
						f7.views.main.router.refreshPage();
	        		});
			});
			
		},
		
		sendTimesheet() {
			const self = this;
						
			if (self.isSent) {
				f7.dialog.alert("Questa settimana è già stata inviata. Per apportare nuove modifiche è necessario richiederne lo sblocco.", () => {
    			});
    			
    			return;
			}
			
			f7.dialog.confirm("Inviare la settimana corrente?", () => {
				//var weekstart = '';
			
				//if (self.ws !== 'undefined')
					//weekstart = self.ws;
				
				ajax.sendTimesheet({appToken:self.user.appToken, appVersion:config.appVersion, ws:self.writableWs},
				
					function(data, status, xhr) {
						console.log('week data', data);
				        
				        if (data.status == 'err') {
				        	f7.dialog.alert(data.msg, () => {
						        if (data.forcelogout !== 'undefined' && parseInt(data.forcelogout) == 1) {
						        	store.dispatch('logout');
									f7.views.main.router.refreshPage();
					        	}
		        			});
		        			f7.preloader.hide();
		        		} else {
							self.isSent = true;
							self.createWeekList(self.writableWs, 0, null, false);
		        		}
		        		
		        		//f7.preloader.hide();
					},
					
					function(xhr, status, message) {
						f7.preloader.hide();
				        f7.dialog.alert(status, () => {
					        store.dispatch('logout');
							f7.views.main.router.refreshPage();
		        		});
				});
			});

		},
		
		exportMonth() {
			const self = this;
			
			if (self.writableWs === 'undefined')
				self.writableWs = '';
			
			ajax.getMonthlyExport({appToken:self.user.appToken, appVersion:config.appVersion, ws:self.writableWs},
			
				function(data, status, xhr) {
					
					console.log('export', data);
					
					if (data.status == 'ok') {
						
						f7.dialog.alert('Il file richiesto è stato generato: <b><a class="link external" href="'+config.request.url+'/'+data.export_path+'" target="_blank">tocca qui per scaricarlo</a></b>.', () => {});
					} else {
						f7.dialog.alert('Errore durante la generazione del file, riprovare tra alcuni istanti.', () => {});
					}
					
					//var arrayBuffer = data;
					
					//If you want to access the bytes:
					//var byteArray = new Uint8Array(arrayBuffer);
					
					//var blob = new Blob([arrayBuffer], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
					//var url = URL.createObjectURL(blob);
					
					//window.open(url);	
					   		
			   		
					/*setTimeout(() => {
						window.URL.revokeObjectURL(url);
      				}, 100);*/
      				
      				f7.preloader.hide();
					
					  /* var reader = new FileReader();
					   reader.onloadend = function(e) {
					   		window.open(reader.result);	
					   		
					   		//console.log('reader.result', reader.result);
					   		
							setTimeout(() => {
								window.URL.revokeObjectURL(url);
		      				}, 100);
					   		
					   		f7.preloader.hide();
        				}
        				
        				reader.onerror = function() {
	        				
	        				f7.dialog.alert("Si è verificato un errore durante l'elaborazione del file, riprovare tra alcuni istanti.", () => {});
	        				
	        				f7.preloader.hide();
        				}
        				
						reader.readAsDataURL(blob);*/
					
	        		
				},
				
				function(xhr, status, message) {
					f7.preloader.hide();
			        f7.dialog.alert(status, () => {
				        store.dispatch('logout');
						f7.views.main.router.refreshPage();
	        		});
			});
			
		},
		
		getWeatherDay(weatherTitle, weatherData) {
			const self = this;
			//console.log('getWeatherDay');
			
			self.weatherTitle = weatherTitle;
			self.weatherData = weatherData;
			
			const weatherPopup = f7.popup.get('.weather-popup');
			
			weatherPopup.open();
		},
		
		setWeatherIcon(code, accurate) {
			const self = this;
			
			var iconList = accurate ? config.weatherIconsAccurate : config.weatherIcons;
			
			//console.log('iconList', iconList);
						
			for (var i in iconList) {
				if (code == iconList[i].type) {
					return 'wi '+iconList[i].cssClass;
				}
			}
			
			return 'wi wi-na';
		},
		
		refreshAll() {
			window.location.reload(true)
		}
	},
};
</script>