<template>
  <f7-page name="paypockets">
  
  
  	    <f7-navbar>
	  <f7-nav-left><f7-link back><f7-icon icon="icon-back"></f7-icon></f7-link></f7-nav-left>
	  <f7-nav-title>Buste paga</f7-nav-title>
	  <f7-nav-right>
	    <f7-link>&nbsp;</f7-link>
	  </f7-nav-right>
	</f7-navbar>
	
	
	<div class="block block-strong">
		<p><b>Area in costruzione</b>, le informazioni visualizzate si intendono a titolo esemplificativo.</p>
	</div>
	
	
	
	  <div class="block block-strong no-padding-horizontal">
		  
		  
        <div class="treeview">
          <div class="treeview-item">
            <div class="treeview-item-root">
              <div class="treeview-toggle"></div>
              <div class="treeview-item-content">
                <i class="icon f7-icons">folder_fill</i>
                <div class="treeview-item-label">2021</div>
              </div>
            </div>
            <div class="treeview-item-children">
              <div class="treeview-item">
                <div class="treeview-item-root">
                  <div class="treeview-item-content">
                    <i class="icon f7-icons">doc_text_fill</i>
                    <div class="treeview-item-label">Marzo.pdf</div>
                  </div>
                </div>
              </div>
              <div class="treeview-item">
                <div class="treeview-item-root">
                  <div class="treeview-item-content">
                    <i class="icon f7-icons">doc_text_fill</i>
                    <div class="treeview-item-label">Febbraio.pdf</div>
                  </div>
                </div>
              </div>
              <div class="treeview-item">
                <div class="treeview-item-root">
                  <div class="treeview-item-content">
                    <i class="icon f7-icons">doc_text_fill</i>
                    <div class="treeview-item-label">Gennaio.pdf</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          
          <div class="treeview-item">
            <div class="treeview-item-root">
              <div class="treeview-toggle"></div>
              <div class="treeview-item-content">
                <i class="icon f7-icons">folder_fill</i>
                <div class="treeview-item-label">2020</div>
              </div>
            </div>
            <div class="treeview-item-children">
              <div class="treeview-item">
                <div class="treeview-item-root">
                  <div class="treeview-item-content">
                    <i class="icon f7-icons">doc_text_fill</i>
                    <div class="treeview-item-label">Dicembre.pdf</div>
                  </div>
                </div>
              </div>
              <div class="treeview-item">
                <div class="treeview-item-root">
                  <div class="treeview-item-content">
                    <i class="icon f7-icons">doc_text_fill</i>
                    <div class="treeview-item-label">Novembre.pdf</div>
                  </div>
                </div>
              </div>
              <div class="treeview-item">
                <div class="treeview-item-root">
                  <div class="treeview-item-content">
                    <i class="icon f7-icons">doc_text_fill</i>
                    <div class="treeview-item-label">Ottobre.pdf</div>
                  </div>
                </div>
              </div>
              <div class="treeview-item">
                <div class="treeview-item-root">
                  <div class="treeview-item-content">
                    <i class="icon f7-icons">doc_text_fill</i>
                    <div class="treeview-item-label">...</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
	
	
	
	
  	
  </f7-page>
</template>

<script>
import { f7, useStore, f7ready, f7router } from 'framework7-vue';
import { getDevice } from 'framework7';
import { onMounted } from 'vue';
import store from '../js/store';
import config from '../js/config';
import ajax from '../js/ajax';

export default {
	props: {
    },
	data() {
		return {
		};
	},
	
		mounted() {
		const self = this;
		let toastWithCustomButton;
				
		f7ready((f7) => {
			
			
			toastWithCustomButton = f7.toast.create({
				text: "Hai ricevuto una nuova busta paga.",
				closeButton: true,
				closeButtonText: 'OK',
				closeButtonColor: 'blue',
			});
			
			setTimeout(function() {
				toastWithCustomButton.open();
			}, 1000);

			
      	});
	}
};

</script>
