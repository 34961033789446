//https://forum.framework7.io/t/event-for-when-the-app-comes-to-the-foreground-mobile/8960/3
//https://simedia.tech/blog/show-hide-password-input-values-with-vue-js/
//https://forum.framework7.io/t/solved-change-color-theme-during-runtime/5038

//Scadenza password
//https://guide.convenzionepel.aruba.it/news/pannello-standard-impostare-una-scadenza-password/
//Check posta elettronica lato PHP
//https://www.php.net/manual/en/function.imap-open.php
//Libreria IMAP
//https://github.com/ddeboer/imap#feature-requests

//https://github.com/erikflowers/weather-icons

//API
//https://api.openweathermap.org/data/2.5/forecast?id=3169071&appid=241a763e893ca9c2e4774655b5615480&cnt=40&units=metric

//Icone funzionanti
//https://framework7.io/icons/

// Import Vue
import { createApp } from 'vue';

// Import Framework7
import Framework7 from 'framework7/lite-bundle';

// Import Framework7-Vue Plugin
import Framework7Vue, { registerComponents } from 'framework7-vue/bundle';

// Import Framework7 Styles
import 'framework7/framework7-bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.css';

// Import App Component
import App from '../components/app.vue';

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

// Init App
const app = createApp(App);

// Register Framework7 Vue components
registerComponents(app);

window.isFunction = function(functionToCheck) {
	return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

//localStorage.removeItem('isDarkMode'); localStorage.removeItem('elementsColor');

// Mount the app
app.mount('#app');