
import HomePage from '../pages/home.vue';
import OfflinePage from '../pages/offline.vue';
import AboutPage from '../pages/about.vue';
import FormPage from '../pages/form.vue';
import Paypockets from '../pages/paypockets.vue';
import Profile from '../pages/profile.vue';
import Calendar from '../pages/calendar.vue';


import DynamicRoutePage from '../pages/dynamic-route.vue';
import RequestAndLoad from '../pages/request-and-load.vue';
import NotFoundPage from '../pages/404.vue';

import { getDevice } from 'framework7';
import { f7 } from 'framework7-vue';

function checkDevice({to, resolve, reject}) {
	const deviceInfo = getDevice();

	if (!deviceInfo.android) {
		resolve('/offline/');
	} else {
		reject();
	}
}

var routes = [
  {
    path: '/',
    component: HomePage,
    //redirect: checkDevice,
  },
  {
    path: '/paypockets/',
    component: Paypockets,
    //redirect: checkDevice,
  },
  {
    path: '/profile/',
    component: Profile,
    //redirect: checkDevice,
  },
  {
    path: '/form/:pageTitle/:timedate/:weekNumber/:isEmpty/:isInSentWeek/',
    component: FormPage,
    //redirect: checkDevice,
  },
   {
    path: '/calendar/',
    component: Calendar,
    //redirect: checkDevice,
  },
  {
    path: '/:ws/',
    component: HomePage,
    //redirect: checkDevice,
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
