<template>
  <f7-page name="profile">
  
  
	<f7-navbar>
		<f7-nav-left><f7-link back><f7-icon icon="icon-back"></f7-icon></f7-link></f7-nav-left>
			<f7-nav-title>Profilo</f7-nav-title>
		<f7-nav-right>
		<f7-link>&nbsp;</f7-link>
		</f7-nav-right>
	</f7-navbar>
	
	<f7-block-title medium class="text-align-center">{{displayName}}</f7-block-title>
	<f7-block strong>
		<div class="profile-img-container">
			<div class="profile-img-wrapper">
				<img :src="userAvatar" id="profile-image">
			</div>
			<div class="profile-edit-icon" @click="editAvatar()"><img src="static/icons/pencil.png"></div>
			
			<div v-if="showDeleteButton" class="profile-delete-icon" @click="deleteAvatar()"><img src="static/icons/remove.png"></div>
			
			<form id="avatarinput-form" enctype="multipart/form-data">
				<input type="file" @change="editAvatarChange($event.target.value)" name="file_avatar" id="avatarinput" accept="image/*;capture=camera" style="display: none;">
			</form>
		</div>
		
		<f7-row style="margin-top:20px;" v-if="showAvatarEditButtons">
			<f7-col class="text-align-center">
				<img src="static/icons/uncheck.png" width="35" @click="uncheckAvatar()">
			</f7-col>
			<f7-col class="text-align-center">
				<img src="static/icons/check.png" width="35" @click="uploadAvatar()">
			</f7-col>
		</f7-row>
		
	</f7-block>
	
	<!--<f7-block-title>Gestione password</f7-block-title>
	<f7-list no-hairlines-md>
		<f7-list-input label="Vecchia password" type="password" clear-button></f7-list-input>
		<f7-list-input label="Nuova password" type="password" clear-button></f7-list-input>
		<f7-list-input label="Ripeti password" type="password" clear-button></f7-list-input>
	</f7-list>
	<f7-block>
		<f7-button large small fill round>Modifica password</f7-button>
	</f7-block>-->
		
	<f7-block-title class="text-align-center"><b>Preferenze del tema</b></f7-block-title>
	
	<div class="list accordion-list">
        <ul>
          <li class="accordion-item">
          <a class="item-content item-link" href="#">
              <div class="item-inner">
                <div class="item-title">Modalità sfondo e testo</div>
              </div>
            </a>
            <div class="accordion-item-content">
              <div class="block">
				    <f7-list>	
				        <!--<f7-toggle v-model:checked="isDarkMode" @change="setDarkMode()"></f7-toggle>-->
				        <f7-list-item radio radio-icon="end" :checked="!isDarkMode" @change="setDarkMode(false)" value="false" title="Chiaro" name="is-dark-mode"></f7-list-item>
				        <f7-list-item radio radio-icon="end" :checked="isDarkMode" @change="setDarkMode(true)" value="true" title="Scuro" name="is-dark-mode"></f7-list-item>
				    </f7-list>
              </div>
            </div>
          </li>
          
        <li class="accordion-item">
          <a class="item-content item-link" href="#">
              <div class="item-inner">
                <div class="item-title">Colore degli elementi</div>
              </div>
            </a>
            <div class="accordion-item-content">
              <div class="block">
				    <f7-list>
				    
				   	 <f7-list-item v-for="item in elementColors" radio @change="setElementColor(item.cssClass, item.badgeColor)" radio-icon="end" name="elements_color" :value="item.cssClass" :checked="item.cssClass == elementsColor"><span :class="'badge '+item.badgeColor">{{item.colorName}}</span></f7-list-item>
					
					</f7-list>
              </div>
            </div>
          </li>
          
          <li class="accordion-item">
          <a class="item-content item-link" href="#">
              <div class="item-inner">
                <div class="item-title">Immagine Home</div>
              </div>
            </a>
            <div class="accordion-item-content" style="background-color: #282828;">

				<f7-swiper id="profile-swiper" class="profileSwiper" virtual lazy :slides-per-view="1" :space-between="10" @swiper="onSwiper" @slideChange="onSlideChange" style="padding-bottom: 20px; padding-top: 50px;">
					<f7-swiper-slide  v-for="(slideContent, index) in slides" :virtualIndex="index">
					
					
	<div class="wallpaper-slide-container">
		
		<f7-segmented raised round tag="p" class="slider-buttons">
			<f7-button round fill @click="zoomImage(index)"><i class="f7-icons">zoom_in</i></f7-button>
			<!-- multiply -->
			<f7-button round fill @click="setOrRemoveBackground(index, false)" :color="index == savedImageIndex ? 'red' : ''">
				<i class="f7-icons" v-if="index != savedImageIndex">checkmark_alt</i>
				<i class="f7-icons" v-if="index == savedImageIndex">multiply</i>
			</f7-button>
		</f7-segmented>
		
							<div class="wallpaper-slide-wrapp" v-html="slideContent"></div>
						</div>
						<div class="slider-pagination-numbers"><f7-badge>{{setImageTitle(index)}}</f7-badge></div>

					</f7-swiper-slide>
				</f7-swiper>
			
			<f7-block>
				<f7-button :color="fastBackwordColor" @click="swiperSlideTo()"><i class="f7-icons">arrow_right</i> Corrente</f7-button>
			</f7-block>
			  	
            </div>
          </li>
          
        </ul>
	</div>
	
		 <f7-popup class="zoom-popup">
	    <f7-page :style="'background-image: url('+zoomFile+'); background-repeat: no-repeat; background-size: cover;'">
	    
	    <f7-navbar>
	  <f7-nav-left><f7-link popup-close><f7-icon icon="icon-back"></f7-icon></f7-link></f7-nav-left>
	  <f7-nav-title>{{zoomPopupTitle}}</f7-nav-title>
	  <f7-nav-right>
	    <f7-link>&nbsp;</f7-link>
	  </f7-nav-right>
	</f7-navbar>
	
	<f7-fab position="center-bottom" slot="fixed" @click="setOrRemoveBackground(selectedIndex, true)" :color="selectedIndex == savedImageIndex ? 'red' : ''">
    	<i class="f7-icons" v-if="selectedIndex != savedImageIndex">checkmark_alt</i>
		<i class="f7-icons" v-if="selectedIndex == savedImageIndex">multiply</i>
	</f7-fab>
	
	    </f7-page>
		 </f7-popup>
		
  </f7-page>
</template>

<script>
import { f7, useStore, f7ready, f7router } from 'framework7-vue';
import dom7 from 'dom7';
import { getDevice } from 'framework7';
import { onMounted } from 'vue';
import store from '../js/store';
import config from '../js/config';
import ajax from '../js/ajax';

export default {
	props: {
    },
	data() {
		
		const imageTotal = 100;
		const slides = Array.from({ length: imageTotal }).map(
			(el, index) => '<img class="swiper-lazy" data-src="static/bg'+this.setImageFileName(index)+'.jpeg"><div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div>'
		);		
		return {
			isDarkMode: useStore('getIsDarkMode'),
			elementsColor:'',
			elementColors: config.elementColors,
			user: null,
			userAvatar:null,
			avatarIsDefault:false,
			showDeleteButton:false,
			displayName:null,
			showAvatarEditButtons: false,
			previousImage:'',
			imageTotal,
			slides,
			zoomPopupTitle:'',
			zoomFile:'',
			selectedIndex:-1, //Usato dalla popup dello zoom (v. zoomImage())
			//savedImageTitle:null,
			savedImageIndex:-1,
			zoomPopup: null,
			bgSwiper: null,
			allowFastBackward: false,
			fastBackwordColor: 'gray',
		};
	},
	
	mounted() {
		const self = this;
				
		f7ready((f7) => {
							
			store.dispatch('checkLoginState');					
			self.user = useStore('getUserData');
						
			dom7('#avatarinput-form').trigger('reset');
			
			//self.bgSwiper = document.querySelector('.swiper-container').swiper;
			self.bgSwiper = document.querySelector('.profileSwiper').swiper;
			
			//self.bgSwiper = document.getElementById('profile-swiper').swiper;
			
			self.savedImageIndex = useStore('getBgIndex');
			if (self.savedImageIndex > -1) {
				self.selectedIndex = self.savedImageIndex;
				self.bgSwiper.slideTo(self.savedImageIndex);
			}
						
			if (self.user != null) {
				self.userAvatar = self.user.avatar+'?t='+self.user.loginUniqid;
				self.previousImage = self.userAvatar;
				self.avatarIsDefault = self.user.avatarIsDefault;
				self.showDeleteButton = self.avatarIsDefault ? false : true;
				self.displayName = self.user.displayName; 
			}
			
			let elementsColorData = useStore('getElementsColor');
			let elementsColorObj = {};
			
			if (elementsColorData.value != null) {
				elementsColorObj = JSON.parse(elementsColorData.value);
				self.elementsColor = elementsColorObj.cssClass;
			} else {
				self.elementsColor = '';
			}
						
			//console.log('self.elementsColor', self.elementsColor);
			
      	});
	},
	methods: {
		setImageFileName(index) {
			const self = this;			
			return index.toString().padStart(3, '0');
		},
		setImageTitle(index) {
			const self = this;
			return (index+1)+'/'+self.imageTotal;
		},
		/*removeBackground(index) {
			const self = this;
			self.savedImageTitle = null;
			self.allowFastBackward = false;
			self.fastBackwordColor = 'gray';
			
			console.log('remove', index);
		},*/
		setOrRemoveBackground(index, fromPopup) { //Vedi anche onSlideChange()
			const self = this;
			//self.savedImageTitle = self.setImageTitle(index);
			
			f7.preloader.show();
			
			if (self.savedImageIndex == index) { //Se il pulsante corrisponde all'immagine salvata (è rosso), rimuovi il background
				self.savedImageIndex = -1;
				store.dispatch('removeBgIndex');
			} else {
				self.savedImageIndex = index;
				store.dispatch('setBgIndex', index);
			}
			
			self.fastBackwordColor = 'gray'; //Rendi grigia la freccetta verde che riporta all'immagine salvata
			self.allowFastBackward = false; //Impedisci lo slide (vedi anche onSlideChange())
			f7.views.main.router.back(f7.views.main.router.previousRoute.url, {ignoreCache: true, preload: true, force: true});
			
			if (fromPopup) {
				self.zoomPopup.close();
			}
			
			/*if (self.savedImageIndex < 0) { //Add
				self.savedImageIndex = index;
				//self.allowFastBackward = true;
			} else { //Remove
				self.savedImageIndex = -1;
				//self.allowFastBackward = false;
			}*/
						
			//console.log('set or remove', index);
		},
		zoomImage(index) {
			const self = this;
			const imageTitle = self.setImageTitle(index);
			const imageFile = self.setImageFileName(index);
			
			f7.preloader.show();
			
			self.zoomPopupTitle = 'Sfondo '+imageTitle;
			self.zoomFile = '';
			self.selectedIndex = index;
			self.zoomPopup = f7.popup.get('.zoom-popup');
			
			self.zoomPopup.on('opened', function (popup) {
				self.zoomFile = 'static/bg'+imageFile+'.jpeg';
				f7.preloader.hide();
			});
			
			self.zoomPopup.open();
			
		},
		swiperSlideTo() {
			const self = this;
			if (self.allowFastBackward) {
				self.bgSwiper.slideTo(self.savedImageIndex);
			}
		},
		setDarkMode(value) {
			const self = this;
			
			dom7('body').removeClass('theme-dark');
			
			self.isDarkMode = value;
			
			if (self.isDarkMode) {
				dom7('body').addClass('theme-dark');
			}
			
			//console.log('self.isDarkMode setDarkMode', self.isDarkMode);
			
			store.dispatch('setDarkMode', self.isDarkMode);
			
		},
		setElementColor(cssClass, badgeColor) {
			const self = this;
									
			for(var i in self.elementColors) {
				if (self.elementColors[i].cssClass != '')
					dom7('body').removeClass(self.elementColors[i].cssClass);
			}
			
			if (cssClass != '') {
				dom7('body').addClass(cssClass);
			}
			
			store.dispatch('setElementsColor', {cssClass:cssClass, badgeColor:badgeColor});
			f7.views.main.router.back(f7.views.main.router.previousRoute.url, {ignoreCache: true, preload: true, force: true});
			
			//console.log('setElementColor', useStore('getElementsColor'));

		},
		editAvatar() {
			const self = this;
			
			dom7('#avatarinput').click();
			
			console.log('edit avatar');
		},
		editAvatarChange(evt) {
			const self = this;
			const fileTypes = ['jpg', 'jpeg', 'png'];
			self.previousImage = dom7('#profile-image').attr('src');
			
			var input = dom7('#avatarinput')[0];
			
			if (input.files && input.files[0]) {
				
				self.showDeleteButton = false;
				
				var extension = input.files[0].name.split('.').pop().toLowerCase();
				var size = input.files[0].size;
				var allowed = fileTypes.indexOf(extension) > -1;
				var reader = new FileReader();
				var mb = 5;
				
				if (size > (1024*1024)*mb) {
					allowed = false;
					
					f7.dialog.alert("Il file selezionato è superiore a "+mb+" MB, selezionare un file più piccolo.", () => {});
				}
			
				reader.onload = function() {
					if (allowed) {
						dom7('#profile-image').attr('src', reader.result);
						self.showAvatarEditButtons = true;
					} else {
						self.showAvatarEditButtons = false;
						self.showDeleteButton = self.avatarIsDefault ? false : true;
						dom7('#profile-image').attr('src', self.previousImage);
						dom7('#avatarinput-form').trigger('reset');
					}
				}
				
				reader.onerror = function() {
					self.showAvatarEditButtons = false;
					self.showDeleteButton = self.avatarIsDefault ? false : true;
					dom7('#profile-image').attr('src', self.previousImage);
					dom7('#avatarinput-form').trigger('reset');
				}
			
				reader.readAsDataURL(input.files[0]);
			}
		},
		uploadAvatar() {
			const self = this;
			
			var input = dom7('#avatarinput')[0];
			
			if (input.files && input.files[0]) {
							
				var formData = new FormData();
				formData.append('files', input.files[0]);
				formData.append('ttre_app_token', self.user.appToken);
				
				f7.preloader.show();
				
				f7.request({
			        url:config.request.url+'/dashboard/?dashboard_page=app-upload-avatar.json',
			        method:config.request.method,
			        dataType:config.request.dataType,
			        enctype:'multipart/form-data',
			        cache: false,
			        data:formData,
			        
			        success:function(data, status, xhr) {
				    	//console.log('upload data', data);
				    	self.cleanUploadForm();
				    	
				    	if (data.status == 'ok') {
					    	store.dispatch('replaceUserData', {key:'avatar', value:data.avatarUri});
					    	store.dispatch('replaceUserData', {key:'avatarIsDefault', value:false});
					    	store.dispatch('replaceUserData', {key:'loginUniqid', value:data.loginUniqid});
					    	
					    	self.showDeleteButton = true;
					    	self.avatarIsDefault = false;
					    	
					    	f7.views.main.router.back(f7.views.main.router.previousRoute.url, {ignoreCache: true, preload: true, force: true});
				    	} else {
							f7.dialog.alert("Errore remoto: "+data.msg, () => {
							
							});
							f7.preloader.hide();
						}
				    	
				    	//f7.views.main.router.back(f7.views.main.router.previousRoute.url, {ignoreCache: true, preload: true, force: true});
				    	
				    	//f7.preloader.hide();
			        },
			        
			        error:function(xhr, status, message) {
				        //console.log('error upload data', message);
				        self.cleanUploadForm();
				        f7.preloader.hide();
				        dom7('#profile-image').attr('src', self.previousImage);
				        self.showDeleteButton = self.avatarIsDefault ? false : true;
				        
				        f7.dialog.alert("Errore remoto: "+message, () => {

	        			});
			        }
			        
		        });
	        
	        } else {
		        self.cleanUploadForm();
	        }
			
			
		},
		uncheckAvatar() {
			const self = this;
		
			//console.log('self.previousImage', self.previousImage);
			
			self.cleanUploadForm();
			dom7('#profile-image').attr('src', self.previousImage);
			self.showDeleteButton = self.avatarIsDefault ? false : true;
		},
		deleteAvatar() {
			const self = this;
			
			f7.dialog.confirm("Eliminare l'immagine di Profilo corrente?", () => {
				
				f7.preloader.show();
				
				f7.request({
			        url:config.request.url+'/dashboard/?dashboard_page=app-delete-avatar.json',
			        method:config.request.method,
			        dataType:config.request.dataType,
			        data:{ttre_app_token:self.user.appToken},
			        
			        success:function(data, status, xhr) {
				        
				        //console.log('data', data);
				        
				    	self.cleanUploadForm();
				    	
				    	if (data.status == 'ok') {
					    	store.dispatch('replaceUserData', {key:'avatar', value:data.avatarUri});
					    	store.dispatch('replaceUserData', {key:'avatarIsDefault', value:true});
					    	store.dispatch('replaceUserData', {key:'loginUniqid', value:data.loginUniqid});
					    	
					    	self.showDeleteButton = false;
					    	self.avatarIsDefault = true;
					    	self.previousImage = data.avatarUri+'?='+data.loginUniqid;
					    	
					    	dom7('#profile-image').attr('src', self.previousImage);
					    	
					    	f7.views.main.router.back(f7.views.main.router.previousRoute.url, {ignoreCache: true, preload: true, force: true});
				    	}
				    	
			        },
			        
			        error:function(xhr, status, message) {

				        f7.preloader.hide();
				        
				        f7.dialog.alert("Errore remoto: "+message, () => {

	        			});
			        }
			        
		        });
				

			});
			
			//self.showDeleteButton = false;
		},
		cleanUploadForm() {
			const self = this;
			self.showAvatarEditButtons = false;
			dom7('#avatarinput-form').trigger('reset');
			self.showDeleteButton = self.avatarIsDefault ? false : true;
		},
	onSwiper(swiper) {
        //console.log(swiper);
      },
      onSlideChange(swiper) {
	  	const self = this;
	  		  	
	  	if (self.savedImageIndex > -1) { //Esiste un indice di immagine salvato
		  	if (swiper.activeIndex != self.savedImageIndex) {
			  	//Mostra il torna indietro
			  	self.allowFastBackward = true;
			  	self.fastBackwordColor = '';
		  	} else {
			  	//Nascondi il torna indietro
			  	self.fastBackwordColor = 'gray';
			  	self.allowFastBackward = false;
		  	}
	  	} else {
		  	//Nascondi il torna indietro
		  	self.fastBackwordColor = 'gray';
		  	self.allowFastBackward = false;
	  	}
	  	
      },
	}
};

</script>