<template>
  <f7-page name="paypockets">
  
  
  	    <f7-navbar>
	  <f7-nav-left><f7-link back><f7-icon icon="icon-back"></f7-icon></f7-link></f7-nav-left>
	  <f7-nav-title>Attività pianificate</f7-nav-title>
	  <f7-nav-right>
	    <f7-link>&nbsp;</f7-link>
	  </f7-nav-right>
	</f7-navbar>
	
  <f7-card v-if="loading"
    class="skeleton-text skeleton-effect-blink"
    title="Card Header"
    content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi lobortis et massa ac interdum. Cras consequat felis at consequat hendrerit."
    footer=""
  ></f7-card>
	
	<div v-if="calendarList.length == 0 && !loading" class="block block-strong">
		<p>Non sono disponibili Attività pianificate.</p>
	</div>
	
	<div v-if="calendarList.length > 0 && !loading" v-for="item in calendarList" class="block block-strong">
		
		<f7-block-title><f7-icon ios="f7:clock" aurora="f7:clock" md="material:clock"></f7-icon> {{getListLabel(item.day_id)}}</f7-block-title>
		
		<f7-list media-list v-if="item.data.length > 0">
			<f7-list-item :disabled="false" v-for="sub_item in item.data" 
					:checkbox="false" 
					name="calendar_checkbox[]"
					:title="sub_item.activity_name"
					:subtitle="sub_item.company_name"
					:text="sub_item.type_name"
					:value="sub_item.item_id"
			>
				<template #header v-if="sub_item.item_status==0"><f7-badge color="orange">SOSPESO</f7-badge></template>
				<template #after><f7-badge style="color:#000; font-weight: bold;">{{sub_item.time_hours.replace('.', ',')+' ore'}}</f7-badge></template>
				<template #footer><f7-block>
					    <f7-row>
      <f7-col>
        <f7-button v-if="sub_item.item_status==1" @click="setStatus(0, sub_item.item_id)">Sospendi</f7-button>
        <f7-button v-if="sub_item.item_status==0" @click="setStatus(1, sub_item.item_id)">Ripristina</f7-button>
      </f7-col>
      <f7-col>
        <f7-button @click="deleteCalendarItem(sub_item.item_id)">Rimuovi</f7-button>
      </f7-col>
    </f7-row></f7-block>
				</template>
			</f7-list-item>
		</f7-list>
	</div>
	

   
   <!--<f7-fab position="center-bottom" text="Inattività" v-if="calendarList.length > 0 && !loading" @click="openPeriodPopup()">-->
  <f7-fab position="center-bottom" text="Inattività" @click="openPeriodPopup()">
   	<f7-icon ios="f7:calendar_badge_plus" aurora="f7:calendar_badge_plus" md="material:calendar_badge_plus"></f7-icon>
   </f7-fab>


 <f7-sheet class="sheet-push" push>
    <f7-toolbar>
      <div class="left"></div>
      <div class="right">
        <f7-link sheet-close>Annulla</f7-link>
      </div>
    </f7-toolbar>
    <f7-page-content>
    <f7-block-title>Periodo di inattività</f7-block-title>

      	<f7-list no-hairlines-md>
	  	<f7-list-input
    label="Periodo"
    type="datepicker"
    placeholder="Seleziona..."
    readonly

    :calendar-params="{openIn: 'customModal', header: true, footer: true, dateFormat: 'MM dd yyyy'}"
  ></f7-list-input>
      	</f7-list>
	  	
	  	
    </f7-page-content>
  </f7-sheet>
  
  
  	<f7-popup class="period-popup">
		<f7-page>
		
		<f7-navbar>
		<f7-nav-left><f7-link popup-close><f7-icon icon="icon-back"></f7-icon></f7-link></f7-nav-left>
		<f7-nav-title>Periodo di inattività</f7-nav-title>
		<f7-nav-right>
		<f7-link><!--<f7-link><f7-icon ios="f7:info_circle" aurora="f7:info_circle" md="material:info_circle"></f7-icon></f7-link>--></f7-link>
		</f7-nav-right>
		</f7-navbar>
		
		<!--<div class="card">
			<div class="card-content card-content-padding">
				<p><small><f7-icon ios="f7:info_circle" aurora="f7:info_circle" md="material:info_circle"></f7-icon> Il Periodo di inattività assume priorità su qualsiasi altra Attività pianificata nei limiti dell'intervallo temporale indicato. L'eventuale pianificazione standard tornerà automaticamente disponibile al termine della scadenza.</small></p>
  			</div>
		</div>-->


      	<f7-list no-hairlines-md>
      	
			<f7-list-input
			label="Periodo"
			type="datepicker"
			placeholder="..."
			input-id="calendar_period"
			readonly
			clear-button
			:calendar-params="{openIn: 'customModal', header: true, footer: true, dateFormat: 'dd/mm/yyyy', rangePicker: true, toolbarCloseText: 'Fatto', headerPlaceholder: 'Seleziona un periodo', closeByBackdropClick:true}"
			:value="inputBeginTime"
			@calendar:change="(value) => inputBeginTime = value"
			></f7-list-input>
			
			<f7-list-input
			label="Tipologia"
			type="select"
			input-id="calendar_type"
			placeholder="Seleziona..."
			v-model:value="inputBeginType"
			>
			<option value="0">...</option>
			<option v-for="item in timeTypes" :value="item.id">{{item.name}}</option>
			</f7-list-input>
			
      	</f7-list>
		
		  <f7-block strong>
    <f7-row>
    <!--<f7-col v-if="hasSavedPeriod">
        <f7-button @click="removePeriod()">Rimuovi</f7-button>
      </f7-col>-->
      <f7-col>
        <f7-button @click="setPeriod()">Aggiungi</f7-button>
      </f7-col>
    </f7-row>
  </f7-block>
  
  <f7-block-title>Periodi programmati</f7-block-title>
  
  <div class="card" v-if="periodList.length == 0">
			<div class="card-content card-content-padding">
				<p><small>Al momento non sono disponibili Periodi di inattività pianificati.</small></p>
  			</div>
		</div>
  
  <f7-list media-list>
		<f7-list-item swipeout v-for="item in periodList"  
		name="periods_list[]"
		:title="item.period.join(' - ')"
		:text="item.type_label"
		:value="item.period_id"
		>	
		
	<f7-swipeout-actions right>
        <f7-swipeout-button color="red" @click="removePeriod(item.period_id)" close><f7-icon ios="f7:trash" aurora="f7:trash" md="material:trash"></f7-icon></f7-swipeout-button>
      </f7-swipeout-actions>
		
			<template #header v-if="item.expired"><f7-badge color="red">SCADUTO</f7-badge></template>
			<!--<template #header v-if="!item.expired"><f7-badge color="green">ATTIVO</f7-badge></template>-->
		</f7-list-item>
  </f7-list>
		
	
		</f7-page>
	</f7-popup>


  	
  </f7-page>
</template>

<script>
import { f7, useStore, f7ready, f7router } from 'framework7-vue';
import dom7 from 'dom7';
import { getDevice } from 'framework7';
import { onMounted } from 'vue';
import store from '../js/store';
import config from '../js/config';
import ajax from '../js/ajax';

export default {
	props: {
    },
	data() {
		return {
			user: null,
			loginState: false,
			calendarList: [],
			showNoActovitiesMsg: false,
			loading: true,
			timeTypes: [],
			inputBeginTime: [],
			inputBeginType: 0,
			hasSavedPeriod:false,
			periodList: []
		};
	},
	
	mounted() {
		const self = this;
				
		f7ready((f7) => {
			store.dispatch('checkLoginState');
			self.loginState = useStore('getLoginState');
					
			self.user = useStore('getUserData');
			
			var userTimeTypes = self.user.timeTypes;
			userTimeTypes.splice(11, 2);
			self.timeTypes = [];
			
			if (userTimeTypes.length > 0) {
				for (var i in userTimeTypes) {
					
					if (parseInt(userTimeTypes[i].working) == 0) {
						self.timeTypes.push(userTimeTypes[i]);
					}
				}
			}
						
			self.loadList();
			
			console.log(self.timeTypes);
					
      	});
	},
	
	methods: {
		getListLabel(day_id) {
			const self = this;
			
			for(var i in config.calendarTypes) {
				if (parseInt(config.calendarTypes[i].id) == parseInt(day_id)) {
					return config.calendarTypes[i].label;
				}
			}
		},
		
		loadList() {
			const self = this;
			var obj = {};
			self.calendarList = [];
			self.loading = true;
			
			ajax.getCalendarItems({appToken:self.user.appToken, appVersion:config.appVersion, obj:obj},
				function(ret, status, xhr) {
					f7.preloader.hide();
					
					console.log('ret', ret);
					
					if (ret.status == 'err') {
						f7.dialog.alert(ret.msg, () => {});
					} else {
						self.calendarList = ret.results;
					}
					
					self.loading = false;
				},
				function(xhr, status, message) {
					f7.preloader.hide();
					self.loading = false;
					f7.dialog.alert(status, () => {
						store.dispatch('logout');
						f7.views.main.router.navigate('/');
					});
				}
			);
		},
		
		setStatus(status, item_id) {
			const self = this;
			
			ajax.setCalendarItemStatus({appToken:self.user.appToken, appVersion:config.appVersion, itemId:item_id, status:status},
				function(ret, status, xhr) {
					f7.preloader.hide();
					
					//console.log('ret', ret);
					
					if (ret.status == 'err') {
						f7.dialog.alert(ret.msg, () => {});
					}
					
					self.loadList();

				},
				function(xhr, status, message) {
					f7.preloader.hide();
					self.loading = false;
					f7.dialog.alert(status, () => {
						store.dispatch('logout');
						f7.views.main.router.navigate('/');
					});
				}
			);
			
		},
		
		deleteCalendarItem(item_id) {
			const self = this;
			
			f7.dialog.confirm("Eliminare l'Attività pianificata selezionata?", function() {
				ajax.removeCalendarItem({appToken:self.user.appToken, appVersion:config.appVersion, itemId:item_id},
					function(ret, status, xhr) {
						f7.preloader.hide();
						
						//console.log('ret', ret);
						
						if (ret.status == 'err') {
							f7.dialog.alert(ret.msg, () => {});
						}
						
						self.loadList();
	
					},
					function(xhr, status, message) {
						f7.preloader.hide();
						self.loading = false;
						f7.dialog.alert(status, () => {
							store.dispatch('logout');
							f7.views.main.router.navigate('/');
						});
					}
				)}
			);
			
		},
		
		setPeriod() {
			const self = this;
			
			var period = dom7('#calendar_period').val();
			var type = dom7('#calendar_type').val();
			
			if (period.indexOf('-') < 0) {
				f7.dialog.alert("L'intervallo di date indicato non è valido.", () => {});
				return;
			}
			
			if (parseInt(type) == 0) {
				f7.dialog.alert("La Tipologia indicata non è valida.", () => {});
				return;
			}
			
			ajax.setCalendarPeriod({appToken:self.user.appToken, appVersion:config.appVersion, period:period, type:type},
				function(ret, status, xhr) {
					f7.preloader.hide();
					
					console.log('ret', ret);
					
					f7.dialog.alert(ret.msg, () => {});
					
					if (ret.status != 'err') {
						self.hasSavedPeriod = true;
						self.renderPeriods();
						self.inputBeginTime = [];
						self.inputBeginType = 0;
					}
					

				},
				function(xhr, status, message) {
					f7.preloader.hide();
					self.loading = false;
					f7.dialog.alert(status, () => {
						store.dispatch('logout');
						f7.views.main.router.navigate('/');
					});
				}
			);
			
			console.log(period);
			console.log(type);
		},
		
		removePeriod(period_id) {
			const self = this;
			
			if (typeof period_id === 'undefined') period_id = 0;
			
			f7.dialog.confirm("Eliminare il Periodo selezionato?", function() {
				ajax.removeCalendarPeriod({appToken:self.user.appToken, appVersion:config.appVersion, periodId:period_id},
					function(ret, status, xhr) {
						//f7.preloader.hide();
						
						console.log('ret', ret);
						
						if (ret.status == 'err') {
							f7.dialog.alert(ret.msg, () => {});
							f7.preloader.hide()
						} else {
							self.inputBeginTime = [];
							self.inputBeginType = 0;
							self.hasSavedPeriod = false;
							self.renderPeriods();
						}
						
	
					},
					function(xhr, status, message) {
						f7.preloader.hide();
						self.loading = false;
						f7.dialog.alert(status, () => {
							store.dispatch('logout');
							f7.views.main.router.navigate('/');
						});
					}
				)
			});
		},
		
		openPeriodPopup() {
			const self = this;
			self.renderPeriods(function() {
				const conf_popup = f7.popup.get('.period-popup');
				conf_popup.open();
			});
		},
		
		renderPeriods(callback) {
			const self = this;
			//const conf_popup = f7.popup.get('.period-popup');
						
			ajax.getCalendarPeriod({appToken:self.user.appToken, appVersion:config.appVersion},
				function(ret, status, xhr) {
					f7.preloader.hide();
					
					
					/*if (ret.data.period.length > 0) {
						self.inputBeginTime = [new Date(ret.data.period[0].y, ret.data.period[0].m, ret.data.period[0].d, 0, 0, 0), new Date(ret.data.period[1].y, ret.data.period[1].m, ret.data.period[1].d, 0, 0, 0)];
						self.inputBeginType = ret.data.type_id;
						self.hasSavedPeriod = true;
					}*/
					
					self.periodList = [];
					
					if (ret.data.list.length > 0) {
						self.periodList = ret.data.list;
					}
					
					console.log('period', ret);
					
					if (typeof callback !== 'undefined')
						callback();			
						//conf_popup.open();			

				},
				function(xhr, status, message) {
					f7.preloader.hide();
					self.loading = false;
					f7.dialog.alert(status, () => {
						store.dispatch('logout');
						f7.views.main.router.navigate('/');
					});
				}
			);
		}
	}
};

</script>